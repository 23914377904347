import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authenticateUser } from "./../../REDUX/Models/users.Model";
import { addUser, signIn, setError } from "./../../REDUX/Slices/users.Slice";
import { useSelector, useDispatch } from "react-redux";
import login from "./../../assets/icons/login.svg";
import "./index.scss";

export default function Login() {
  document.title = `طورنى - تسجيل دخول`;

  const [icon, setIcon] = useState("fa-light fa-lock");
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const err = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser(inputs)
      .then((res) => {
        dispatch(
          addUser({
            response: res.data.user.response,
            token: res.data.user.token,
          }),
        );
        dispatch(signIn());
        navigate("/loading");
      })
      .catch(() => {
        dispatch(
          setError(
            `البريد الالكترونى او كلمة المرور غير صحيحة .. حاول مرة اخرى!`,
          ),
        );
      });
  };

  useEffect(() => {
    dispatch(setError(null));
  }, []);

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="login-img">
            <img src={login} alt="Login" />
          </div>
          <div className="login-form">
            <h2>تسجيل دخول</h2>
            <form onSubmit={handleSubmit}>
              <label className="first">
                <i className="fa-light fa-envelope"></i>
              </label>
              <input
                type="email"
                name="email"
                placeholder="البريد الالكترونى"
                value={inputs.email}
                required
                onChange={handleChange}
              />
              <label
                className="last"
                onClick={() => setIcon("fa-light fa-lock-open")}
                onDoubleClick={() => setIcon("fa-light fa-lock")}
              >
                <i className={icon}></i>
              </label>
              <input
                type={icon == "fa-light fa-lock" ? "password" : "text"}
                name="password"
                placeholder="كلمة السر"
                value={inputs.password}
                required
                onChange={handleChange}
              />
              <button type="submit">دخول</button>
            </form>
            <div className="links">
              <Link to="/contact">نسيت كلمة السر؟</Link>
              <Link to="/register">تسجيل حساب جديد</Link>
              {err && <span>{err}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
