import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUsers } from "./../../REDUX/Models/users.Model";
import { getCategories, getHomeSkills } from "../../REDUX/Models/skills.Model";
import { getCurriculums } from "../../REDUX/Models/curriculums.Model";
import main from "./../../assets/icons/main.svg";
import section from "./../../assets/icons/skills.svg";
import vault from "./../../assets/imgs/vault.png";
import "./index.scss";

export default function Home() {
  document.title = `طورنى - الرئيسية`;

  const texts = [
    "اكتشف مهارات جديدة",
    "تواصل مع المدربين",
    "تعلم عن بعد",
    "اربح جوائز قيمة",
    "انشر مهاراتك الان",
  ];
  const [newText, setNewText] = useState("ابدأ الأن .");
  const [motion, setMotion] = useState("none");
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [users, setUsers] = useState([]);
  const [hidden, setHidden] = useState(true);

  const navigate = useNavigate();

  const handleClick = (id) => {
    getHomeSkills({ category: id })
      .then((res) => {
        setSkills(res.data.skills);
        setHidden(false);
      })
      .catch(() => {
        alert("خطأ");
      });
  };

  useEffect(() => {
    setInterval(() => {
      const randomKey = Math.floor(Math.random() * texts.length);
      setTimeout(() => {
        setNewText(texts[randomKey]);
        setMotion("motion");
      }, 1000);
      setMotion("none");
    }, 2000);
  }, []);

  useEffect(() => {
    getUsers()
      .then((res) => {
        setUsers(res.data.users.length);
      })
      .catch(() => {
        alert("خطأ عرض المستخدمين.");
      });
    getCategories()
      .then((res) => {
        setCategories(res.data.categories.length);
      })
      .catch(() => {
        alert("خطأ عرض التخصصات.");
      });
    getCurriculums()
      .then((res) => {
        setCurriculums(res.data.curriculums.length);
      })
      .catch(() => {
        alert("خطأ عرض المناهج.");
      });
  });

  return (
    <div className="home">
      <div className="container">
        <div className="row">
          <div className="main-text">
            <h2>
              طــورنــى: <span className={motion}>{newText}</span>
            </h2>
            <p>
              يمكنك الأن التواصل مع المدربين في مختلف المهارات من جميع انحاء
              الوطن العربي.
            </p>
            <div className="analytics">
              <div className="row">
                <span>
                  <i className="fa-light fa-chalkboard-user"></i>
                  <p> +{curriculums} مدرب</p>
                </span>
                <span>
                  <i className="fa-light fa-graduation-cap"></i>
                  <p> +{categories} تخصص</p>
                </span>
                <span>
                  <i className="fa-light fa-user"></i>
                  <p> +{users} مستخدم</p>
                </span>
              </div>
            </div>
            <button onClick={() => navigate("/login")}>ابدأ الأن</button>
          </div>
          <div className="main-img">
            <img src={main} alt="Main Hero Image" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="row">
          <div className="section-text">
            <h2>المنصة الأولى في الوطن العربى في فكرتها.</h2>
            <p>
              مع طورنى يمكنك التواصل مع العديد من المدربين والمتخصصين في جميع
              انحاء الوطن العربي للتعلم عن بعد واستكشاف المهارات التقنية والغير
              التقنية .. تصفح اقسام المنصة الأن او ابدأ معنا كا مدرب وساعد في
              نشر العلم مع الكثير من المستخدمين واحصل على العديد من الجوائز
              القيمة.
            </p>
            <button onClick={() => navigate("/login")}>
              ابدأ الأن مع طورنى
            </button>
          </div>
          <div className="section-img-l">
            <img src={section} alt="section-img" />
          </div>
        </div>
      </div>
      <div className="section-fw">
        <h2>جرب التعلم مع طورنى</h2>
        <p>
          مع طورنى انت على بعد خطوة واحدة من تعلم مهارة جديدة يمكنك الاختيار بين
          اكثر التخصصات طلبًا في سوق العمل العربي.
        </p>
        <div className="row">
          <span onClick={() => handleClick("ادارية")}>
            <i className="fa-light fa-pen-line"></i>
            <p> مهارات ادارية</p>
          </span>
          <span onClick={() => handleClick("تقنية")}>
            <i className="fa-light fa-code"></i>
            <p> مهارات تقنية</p>
          </span>
          <span onClick={() => handleClick("فنية")}>
            <i className="fa-light fa-camera"></i>
            <p> مهارات فنية</p>
          </span>
        </div>
        <div className="content" style={{ display: hidden ? "none" : "flex" }}>
          <div className="skills">
            {skills.map((skill, index) => {
              return (
                <Link to={`/category/${skill.category}`} key={index}>
                  <div className="skill-box">
                    <i className="fa-duotone fa-shapes"></i>
                    <p>{skill.name}</p>
                  </div>
                </Link>
              );
            })}
          </div>
          {skills.length > 0 ? (
            <button onClick={() => navigate(`/category/${skills[0].category}`)}>
              عرض الكل
            </button>
          ) : (
            ""
          )}
        </div>
        <button onClick={() => navigate("/categories")}>تصفح التخصصات</button>
      </div>
      <div className="section">
        <div className="row">
          <div className="section-img-r">
            <img src={vault} alt="section-img" />
          </div>
          <div className="section-text">
            <h2>سجل مع طورنى الأن واحصل على جوائز قيمة.</h2>
            <p>
              سجل مع منصة طورنى وساهم في نشر العلم والمهارات على مستوى الوطن
              العربي وساعد الكثير على تطوير مهاراتهم وقدراتهم في المجال المفضل
              اليك واحصل على مكافأت وجوائز قيمة.
            </p>
            <button onClick={() => navigate("/register")}> سجل الأن</button>
          </div>
        </div>
      </div>
    </div>
  );
}
