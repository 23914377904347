import axios from "axios";
import { token } from "./../Storage/getItems";

export const getCurriculums = async () => {
  try {
    const response = await axios.get(`/api/curriculums`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCurriculum = async (id) => {
  try {
    const response = await axios.get(`/api/curriculums/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserCurriculums = async (id) => {
  try {
    const response = await axios.get(`/api/curriculums/view/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTopCurriculums = async () => {
  try {
    const response = await axios.get(`/api/curriculums/public/top`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCurriculumforUsers = async () => {
  try {
    const response = await axios.get(`/api/curriculums/public/users`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPublicCurriculums = async () => {
  try {
    const response = await axios.get(`/api/curriculums/admin/public`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPrivateCurriculums = async () => {
  try {
    const response = await axios.get(`/api/curriculums/admin/private`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserPublicCurriculums = async (values) => {
  try {
    const response = await axios.post(`/api/curriculums/user/public`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserPrivateCurriculums = async (values) => {
  try {
    const response = await axios.post(`/api/curriculums/user/private`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const createCurriculums = async (values) => {
  try {
    const response = await axios.post(`/api/curriculums/create`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCurriculum = async (id, values) => {
  try {
    const response = await axios.put(`/api/curriculums/update/${id}`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const publishCurriculum = async (id) => {
  try {
    const response = await axios.put(`/api/curriculums/publish/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCurriculum = async (id) => {
  try {
    const response = await axios.delete(`/api/curriculums/delete/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
