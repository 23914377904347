import axios from "axios";
import { token } from "./../Storage/getItems";

export const getSkills = async (id) => {
  try {
    const response = await axios.get(`/api/skills/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSkill = async (id) => {
  try {
    const response = await axios.get(`/api/skills/skill/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getHomeSkills = async (values) => {
  try {
    const response = await axios.post(`/api/skills/category`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCategories = async () => {
  try {
    const response = await axios.get(`/api/skills/`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSearch = async (values) => {
  try {
    const response = await axios.post(`/api/skills/search`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTrainer = async (values) => {
  try {
    const response = await axios.post(`/api/skills/trainer`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const createSkill = async (values) => {
  try {
    const response = await axios.post(`/api/skills/`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteSkill = async (id) => {
  try {
    const response = await axios.delete(`/api/skills/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
