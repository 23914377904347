import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getTopCurriculums,
  getCurriculumforUsers,
} from "../../REDUX/Models/curriculums.Model";
import avatar from "./../../assets/icons/avatar.svg";
import teacher from "./../../assets/icons/teacher.svg";
import Date from "../../Components/Date";
import "./index.scss";

export default function Curriculums() {
  document.title = `طورنى - المناهج`;

  const [topRated, setTopRated] = useState([]);
  const [curriculums, setCurriculums] = useState([]);

  useEffect(() => {
    getTopCurriculums()
      .then((res) => {
        setTopRated(res.data.curriculums);
      })
      .catch(() => {
        navigate("/");
      });
    getCurriculumforUsers()
      .then((res) => {
        setCurriculums(res.data.curriculums);
      })
      .catch(() => {
        navigate("/");
      });
  }, []);

  return (
    <div className="curriculums">
      <div className="container">
        <div className="header">
          <div className="main-text">
            <h2>
              <span>مناهج</span> طورنى
            </h2>
            <p>يمكنك الان تصفح المناهج المتاحة والاشتراك فيها مجانا.</p>
          </div>
          <div className="img">
            <img src={teacher} alt="Teacher Image" />
          </div>
        </div>
        <div className="row">
          <div className="top-rated">
            <h2>
              <i className="fa-duotone fa-fire-flame"></i>
              الاعلى تقيماً
            </h2>
            <div className="curriculums-row">
              {topRated.length >= 1
                ? topRated.map((el, index) => {
                    return (
                      <div className="curriculum-box" key={index}>
                        <div className="user-info">
                          <img
                            src={el.image != null ? el.image : avatar}
                            alt="User Image"
                          />
                          <p>
                            <Link to={`/profile/${el.u_id}`}>
                              {el.first_name} {el.last_name}
                            </Link>
                          </p>
                          <p>
                            <i className="fa-solid fa-star"></i>{" "}
                            {el.rating + " نجوم"}
                          </p>
                        </div>
                        <p>{el.title}</p>
                        <p>{el.description}</p>
                        <span>
                          <Date date={el.created_date} />
                        </span>
                        <Link to={`/curriculum/${el.curr_id}`}>عرض</Link>
                      </div>
                    );
                  })
                : "لا يوجد مناهج"}
            </div>
          </div>
          <div className="recently-uploaded">
            <h2>
              <i className="fa-duotone fa-clock-rotate-left"></i>
              مضاف حديثاً {`(${curriculums.length})`}
            </h2>
            <div className="curriculums-row">
              {curriculums.length >= 1
                ? curriculums.map((curriculum, index) => {
                    return (
                      <div className="curriculum-box" key={index}>
                        <div className="user-info">
                          <img
                            src={
                              curriculum.image != null
                                ? curriculum.image
                                : avatar
                            }
                            alt="User Image"
                          />
                          <p>
                            <Link to={`/profile/${curriculum.u_id}`}>
                              {curriculum.first_name} {curriculum.last_name}
                            </Link>
                          </p>
                          <p>
                            <i className="fa-solid fa-star"></i>{" "}
                            {curriculum.rating + " نجوم"}
                          </p>
                        </div>
                        <p>{curriculum.title}</p>
                        <p>{curriculum.description}</p>
                        <span>
                          <Date date={curriculum.created_date} />
                        </span>
                        <Link to={`/curriculum/${curriculum.curr_id}`}>
                          عرض
                        </Link>
                      </div>
                    );
                  })
                : "لا يوجد مناهج"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
