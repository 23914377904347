import axios from "axios";
import { token } from "../Storage/getItems";

export const createNotifi = async (values) => {
  try {
    const response = await axios.post(`/api/notifications`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getNotifications = async (id) => {
  try {
    const response = await axios.get(`/api/notifications/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteNotification = async (id) => {
  try {
    const response = await axios.delete(`/api/notifications/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
