import { createSlice } from "@reduxjs/toolkit";
import {
  id,
  user_name,
  first_name,
  last_name,
  email,
  token,
  location,
  bio,
  rating,
  facebook,
  twitter,
  whatsapp,
  image,
  verified,
} from "./../Storage/getItems";
import removeItems from "./../Storage/removeItems";

const usersSlice = createSlice({
  name: "USERS",
  initialState: {
    userData: {
      id: id ? id : null,
      user_name: user_name ? user_name : null,
      first_name: first_name ? first_name : null,
      last_name: last_name ? last_name : null,
      email: email ? email : null,
      token: token ? token : null,
      location: location ? location : null,
      bio: bio ? bio : null,
      rating: rating ? rating : null,
      facebook: facebook ? facebook : null,
      twitter: twitter ? twitter : null,
      whatsapp: whatsapp ? whatsapp : null,
      image: image ? image : null,
      verified: verified ? verified : false,
    },
    password: null,
    isSigned: token ? true : false,
    error: null,
  },
  reducers: {
    addUser: (state, action) => {
      (state.userData = action.payload.response),
        (state.userData.token = action.payload.token);
    },
    editUser: (state, action) => {
      (state.userData.first_name = action.payload.response.first_name),
        (state.userData.last_name = action.payload.response.last_name),
        (state.userData.location = action.payload.response.location),
        (state.userData.bio = action.payload.response.bio),
        (state.userData.facebook = action.payload.response.facebook),
        (state.userData.twitter = action.payload.response.twitter),
        (state.userData.whatsapp = action.payload.response.whatsapp),
        (state.userData.token = action.payload.token);
    },
    addPassword: (state, action) => {
      state.password = action.payload.password;
    },
    removePassword: (state) => {
      state.password = null;
    },
    signIn: (state) => {
      state.isSigned = true;
      state.error = null;
    },
    signOut: (state) => {
      state.isSigned = false;
      state.userData = {
        id: null,
        user_name: null,
        first_name: null,
        last_name: null,
        email: null,
        token: null,
        location: null,
        bio: null,
        facebook: null,
        twitter: null,
        whatsapp: null,
        image: null,
      };
      removeItems();
    },
    userVerify: (state) => {
      state.userData.verified = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  addUser,
  editUser,
  addPassword,
  removePassword,
  signIn,
  signOut,
  userVerify,
  setError,
} = usersSlice.actions;

export default usersSlice.reducer;
