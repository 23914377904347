import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getPosts,
  createPost,
  deletePost,
} from "../../../../REDUX/Models/posts.Model";
import { useDispatch, useSelector } from "react-redux";
import { setError, signOut } from "./../../../../REDUX/Slices/users.Slice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.scss";

export default function Posts() {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("نشر");
  const [posts, setPosts] = useState([]);
  const [hidden, setHidden] = useState(false);

  const err = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const handleClick = (id) => {
    deletePost(id)
      .then()
      .catch(() => {
        dispatch(signOut());
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.length > 100) {
      createPost({ title: title, description: value, image: image })
        .then(() => {
          setTimeout(() => {
            setText("نشر");
          }, 1000);
          setText("تم النشر");
          setTitle("");
          setValue("");
          setImage("");
        })
        .catch(() => {
          dispatch(
            setError("يجب عدم تكرار اسم التدوينة او تحقق من عدد الاحرف."),
          );
          setTimeout(() => {
            dispatch(setError(null));
          }, 2000);
        });
    }
  };

  useEffect(() => {
    getPosts()
      .then((res) => {
        setPosts(res.data.posts);
      })
      .catch(() => {
        dispatch(signOut());
      });
  }, [posts]);

  return (
    <div className="admin-posts" style={{ display: hidden ? "none" : "block" }}>
      <div className="container">
        <header>
          <h2>التدوينات</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <p>* القيمة العظمي للتدوينة 5000 حرف ..</p>
        <div className="row">
          <div className="add-post">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={title}
                placeholder="عنوان التدوينة"
                required
                onChange={(e) => setTitle(e.target.value)}
              />
              <ReactQuill
                className="editor"
                theme="snow"
                value={value}
                onChange={setValue}
                required
              />
              <input
                type="text"
                value={image}
                placeholder="رابط صورة"
                required
                onChange={(e) => setImage(e.target.value)}
              />
              <button className="btn" type="submit">
                {text}
              </button>
              {value.length > 0 ? (
                <label>عدد الحروف: {value.length}</label>
              ) : (
                ""
              )}
            </form>
            {err && err}
          </div>
          <div className="view-posts">
            {posts.length >= 1
              ? posts.map((post, index) => {
                  return (
                    <div className="post" key={index}>
                      <span onClick={() => handleClick(post.id)}>
                        <i className="fa-light fa-trash"></i>
                      </span>
                      <p>
                        <Link to={`/post/${post.id}`}>{post.title}</Link>
                      </p>
                      <img src={post.image} alt="Post Image" />
                    </div>
                  );
                })
              : `لا يوجد تدوينات للعرض`}
          </div>
        </div>
      </div>
    </div>
  );
}
