import { useState, useEffect } from "react";
import Date from "../../../../Components/Date";
import {
  getIncorrectAnswers,
  updateAnswer,
  deleteAnswer,
} from "./../../../../REDUX/Models/answers.Model";
import { createNotifi } from "../../../../REDUX/Models/notifi.Model";
import { updateRating } from "../../../../REDUX/Models/users.Model";
import { createCertificate } from "../../../../REDUX/Models/certificates.Model";
import { useDispatch } from "react-redux";
import { signOut } from "./../../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Answers() {
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState({ notification: "", rating: "" });
  const [hidden, setHidden] = useState(false);
  const [text, setText] = useState(<i className="fa-light fa-paper-plane"></i>);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (user_id, category_id) => {
    if (user.notification.length > 1 && user.rating.length > 0) {
      createNotifi({ user_id: user_id, text: user.notification })
        .then(
          updateRating({ user_id: user_id, rating: user.rating }),
          createCertificate({ user_id: user_id, category_id: category_id }),
        )
        .then(
          setTimeout(() => {
            setText(<i className="fa-light fa-paper-plane"></i>);
          }, 1000),
          setText("تم الارسال."),
        )
        .catch(() => {
          alert("خطأ انشاء نتيجة.");
          dispatch(signOut());
        });
    }
  };

  const handleClick = (id) => {
    updateAnswer({ id: id }).then();
  };

  const handleDelete = (id) => {
    deleteAnswer(id).then();
  };

  useEffect(() => {
    getIncorrectAnswers()
      .then((res) => {
        setAnswers(res.data.answers);
      })
      .catch(() => {
        alert("خطأ عرض الاجابات");
        dispatch(signOut());
      });
  }, [answers]);

  return (
    <div
      className="admin-answers"
      style={{ display: hidden ? "none" : "block" }}
    >
      <div className="container">
        <header>
          <h2>الاجابات</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <p>
          * يرجى ارسال ملاحظة واضافة تقيم لكل متدرب في حالة النجاح او الرسوب ..
          <span>عدد الاجابات: {answers.length}</span>
        </p>
        <div className="row">
          <table>
            {answers.length > 0
              ? answers.map((answer, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <th className="head">السؤال: </th>
                        <th>{answer.q1}</th>
                        <th>{answer.q2}</th>
                        <th>{answer.q3}</th>
                        <th>{answer.q4}</th>
                        <th>{answer.q5}</th>
                        <th>ملاحظة</th>
                        <th>ناجح </th>
                        <th>راسب</th>
                        <th>التاريخ</th>
                      </tr>
                      <tr className="answer">
                        <th className="head">الاجابة: </th>
                        <th>{answer.a1}</th>
                        <th>{answer.a2}</th>
                        <th>{answer.a3}</th>
                        <th>{answer.a4}</th>
                        <th>{answer.a5}</th>
                        <th>
                          <input
                            type="text"
                            name="notification"
                            placeholder="ارسال ملاحظة للمتدرب"
                            value={user.notification}
                            required
                            onChange={handleChange}
                          />
                          <input
                            type="number"
                            name="rating"
                            placeholder="تقيم المتدرب"
                            value={user.rating}
                            required
                            onChange={handleChange}
                          />
                          <button
                            className="btn"
                            onClick={() =>
                              handleSubmit(answer.user_id, answer.category_id)
                            }
                          >
                            {text}
                          </button>
                        </th>
                        <th>
                          <button
                            className="success"
                            onClick={() => handleClick(answer.id)}
                          >
                            ناجح
                          </button>
                        </th>
                        <th>
                          <button
                            className="fail"
                            onClick={() => handleDelete(answer.id)}
                          >
                            راسب
                          </button>
                        </th>
                        <th>
                          <Date date={answer.created_date} />
                        </th>
                      </tr>
                    </tbody>
                  );
                })
              : "لا يوجد اجابات للعرض"}
          </table>
        </div>
      </div>
    </div>
  );
}
