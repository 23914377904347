import { Link } from "react-router-dom";

export default function ProfileCurriculums(props) {
  return (
    <div className="curriculum-box">
      <i className="fa-duotone fa-graduation-cap"></i>
      <p>{props.curriculum.title}</p>
      <p>{props.curriculum.description}</p>
      <Link to={`/curriculum/${props.curriculum.id}`}>عرض</Link>
    </div>
  );
}
