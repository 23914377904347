import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getSkill } from "./../../REDUX/Models/skills.Model";
import { useDispatch } from "react-redux";
import { signOut } from "./../../REDUX/Slices/users.Slice";
import Core from "./../../Components/Test";
import "./index.scss";

export default function Test() {
  const id = useParams().id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [skill, setSkill] = useState({});
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    getSkill(id).then((res) => {
      if (res.data.skill) {
        setSkill(res.data.skill);
      } else {
        dispatch(signOut());
        navigate("/");
      }
    });
  }, []);

  document.title = `طورنى - اختبار مهارات ${skill.category}`;
  return (
    <div className="test">
      <div className="container">
        <div className="header">
          <Link to="/">
            <h2>طورنى</h2>
          </Link>
        </div>
        <div className="content">
          <div className="intro">
            <h2>
              اختبار في تخصص <span>"مهارات {skill.category}"</span>
            </h2>
            <p>
              مع اختبارت طورنى يمكنك تقيم نفسك على مهاراتك من خلال اختبار موثوق
              من جهات عالمية تقوم بدراسة هذة المهارة{" "}
            </p>
            <p>
              {" "}
              في الصفحة التالية سوف نقوم بتحويلك على صفحة الاختبار, بعد الانتهاء
              من الاختبار اضغط على زر ارسال في الاسفل لارسال الاختبار للمراجعة
              من قبل مراجعين متخصصين في هذة المهارة وبعد ذلك نقوم بارسال اشعار
              لك يفيد بنتيحة اختبارك ..
            </p>
            <p>
              في حالة اجتياز الاختبار يمكنك بدأ نشر مهاراتك من خلال انشاء منهج
              يحتوى على شرح كامل ومفصل للمواضيع الاساسية في هذة المهارة بالاضافة
              الى وضع اختبار للمستخدمين المشتركين في هذا المنهج
            </p>
            <p>شروط الاختبار: </p>
            <ul>
              <li>الاختبار مكون من خمس نقاط</li>
              <li>لا يجب قفل المتصفح اثناء الاختبار</li>
              <li>يجب الانتهاء من الاختبار قبل الوقت المحدد</li>
              <li>في حالة عدم اجتياز الاختبار يمكنك المحاولة مرة اخرى</li>
              <li>في حالة فوات وقت الاجابة على السؤال تصنف الاجابة خاطئة</li>
            </ul>
            <p>
              * تنوية: تقدم طورنى اكثر من اختبار في نفس المهارة ونقوم بعرض
              اختبار واحد في كل مرة عشوائيا ً لمنع عمليات الغش.
            </p>
            <button onClick={() => setHidden(false)}>ابدأ الاختبار</button>
          </div>
          <div className="core-test">
            <Core params={id} hidden={hidden} />
          </div>
        </div>
      </div>
    </div>
  );
}
