import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export default function Loading() {
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state);

  setTimeout(() => {
    navigate("/dashboard");
    window.location.reload();
  }, 1000);

  useEffect(() => {
    window.localStorage.setItem("user_id", JSON.stringify(userData.id));
    window.localStorage.setItem(
      "user_name",
      JSON.stringify(userData.user_name),
    );
    window.localStorage.setItem(
      "first_name",
      JSON.stringify(userData.first_name),
    );
    window.localStorage.setItem(
      "last_name",
      JSON.stringify(userData.last_name),
    );
    window.localStorage.setItem("email", JSON.stringify(userData.email));
    window.localStorage.setItem("token", JSON.stringify(userData.token));
    window.localStorage.setItem("location", JSON.stringify(userData.location));
    window.localStorage.setItem("bio", JSON.stringify(userData.bio));
    window.localStorage.setItem("rating", JSON.stringify(userData.rating));
    window.localStorage.setItem("facebook", JSON.stringify(userData.facebook));
    window.localStorage.setItem("twitter", JSON.stringify(userData.twitter));
    window.localStorage.setItem("whatsapp", JSON.stringify(userData.whatsapp));
    window.localStorage.setItem("image", JSON.stringify(userData.image));
    window.localStorage.setItem("verified", JSON.stringify(userData.verified));
  });

  return (
    <div className="loading">
      <div className="container">
        <h2>جارى التحميل</h2>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}
