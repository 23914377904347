import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getUser } from "./../../../REDUX/Models/users.Model";
import { getSkills } from "./../../../REDUX/Models/skills.Model";
import { getUserCertificates } from "../../../REDUX/Models/certificates.Model";
import { useSelector } from "react-redux";
import avatar from "./.././../../assets/icons/avatar.svg";
import Skills from "../Skills";
import Certificate from "./../../../Components/Certificate";
import "./../index.scss";

export default function View() {
  const user_id = useParams().id;

  const [user, setUser] = useState({});
  const [skills, setSkills] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [skillsHidden, setSkillsHidden] = useState(false);
  const [friendsHidden, setFriendsHidden] = useState(true);
  const [curriculumsHidden, setCurriculumsHidden] = useState(true);
  const [certificatesHidden, setCertificatesHidden] = useState(true);

  const isSigned = useSelector((state) => state.isSigned);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSigned == false) {
      getUser(user_id)
        .then((res) => {
          if (res.data.user) {
            setUser(res.data.user);
          } else {
            navigate("/");
          }
          getSkills(user_id).then((res) => {
            setSkills(res.data.skills);
          });
          getUserCertificates(user_id).then((res) => {
            setCertificates(res.data.certificates);
          });
        })
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate(`/profile/${user_id}`);
    }
  }, [isSigned]);

  document.title = `طورنى - ${user.first_name}${" "}${user.last_name}`;
  return (
    <div className="profile">
      <div className="container">
        <h2 className="header">الصفحة الشخصية</h2>
        <div className="row">
          <div className="profile-img">
            <img src={avatar} alt="Profile Image" />
          </div>
          <div className="profile-info">
            <div className="info">
              <div className="info-header">
                <h2>
                  {user.first_name} {user.last_name}
                </h2>
                <button onClick={() => navigate("/login")}>تسجيل دخول</button>
              </div>
              <i className="fa-light fa-at"></i>
              <p>{user.user_name}</p>
            </div>
            <div className="location">
              <i className="fa-light fa-location-dot"></i>
              <p>
                {user.location ? user.location : <span>لا يوجد مدينة.</span>}
              </p>
            </div>
            <div className="rating">
              <i className="fa-solid fa-star"></i>
              <p>
                {user.rating ? (
                  <span>{`${user.rating} نجوم`}</span>
                ) : (
                  <span>لا يوجد تقيم.</span>
                )}
              </p>
            </div>
            <div className="bio">
              <i className="fa-solid fa-hashtag"></i>
              <p>{user.bio ? user.bio : <span>لا يوجد نبذة تعريفية.</span>}</p>
            </div>
          </div>
        </div>
        <div className="profile-content">
          <div className="toggle">
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(false);
                setCertificatesHidden(true);
                setFriendsHidden(true);
              }}
            >
              المهارات <i className="fa-solid fa-shapes"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(true);
                setCertificatesHidden(false);
                setFriendsHidden(true);
              }}
            >
              الشهادات <i className="fa-solid fa-file-certificate"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(false);
                setSkillsHidden(true);
                setCertificatesHidden(true);
                setFriendsHidden(true);
              }}
            >
              المناهج <i className="fa-solid fa-graduation-cap"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(true);
                setCertificatesHidden(true);
                setFriendsHidden(false);
              }}
            >
              الاصدقاء <i className="fa-solid fa-user-group"></i>
            </p>
          </div>
          <div className="component">
            <div
              className="profile-curriculums"
              style={{ display: curriculumsHidden ? "none" : "flex" }}
            >
              <span className="noData">
                يجب <Link to="/login">تسجيل دخول</Link> لعرض المناهج.
              </span>
            </div>
            <div
              className="profile-skills"
              style={{ display: skillsHidden ? "none" : "flex" }}
            >
              {skills.length > 0 ? (
                skills.map((skill, index) => {
                  return <Skills key={index} skill={skill} />;
                })
              ) : (
                <span className="noData">لا يوجد مهارات للعرض.</span>
              )}
            </div>
            <div
              className="profile-certificates"
              style={{ display: certificatesHidden ? "none" : "flex" }}
            >
              {certificates.length > 0 ? (
                certificates.map((certificate, index) => {
                  return (
                    <Certificate key={index} certificateProp={certificate} />
                  );
                })
              ) : (
                <span className="noData">لا يوجد شهادات للعرض.</span>
              )}
            </div>
            <div
              className="profile-friends"
              style={{ display: friendsHidden ? "none" : "flex" }}
            >
              <span className="noData">
                يجب <Link to="/login">تسجيل دخول</Link> لعرض الاصدقاء.
              </span>
            </div>
          </div>
        </div>
        <div className="user-contact">
          <h2>معلومات التواصل</h2>
          <div className="contact-info">
            {user.facebook ? (
              <Link to={user.facebook} target="_blank">
                <div className="social-box">
                  <p>Facebook</p>
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
              </Link>
            ) : (
              "لا يوجد معلومات للتواصل."
            )}
            {user.twitter ? (
              <Link to={user.twitter} target="_blank">
                <div className="social-box">
                  <p>Twitter</p>
                  <i className="fa-brands fa-twitter"></i>
                </div>
              </Link>
            ) : (
              ""
            )}
            {user.whatsapp ? (
              <Link to={`https://wa.me/${user.whatsapp}`} target="_blank">
                <div className="social-box">
                  <p>Whatsapp</p>
                  <i className="fa-brands fa-whatsapp"></i>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
