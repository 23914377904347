import { useState, useEffect } from "react";
import { getUsersTests } from "./../../../REDUX/Models/tests.Model";
import { getSearch } from "./../../../REDUX/Models/skills.Model";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut, setError } from "../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Tests(props) {
  const dispatch = useDispatch();

  const [tests, setTests] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState({ search: "" });
  const [hidden, setHidden] = useState("none");

  const handleChange = (e) => {
    setSearch({ search: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getSearch(search).then((res) => {
      setSearchResult(res.data.categories);
    });
    setHidden("flex");
  };

  const handleClick = () => {
    setSearchResult([]);
    setHidden("none");
    setSearch({ search: "" });
  };

  useEffect(() => {
    getUsersTests()
      .then((res) => {
        setTests(res.data.tests);
      })
      .catch(() => {
        dispatch(signOut());
      });
    dispatch(setError(null));
  }, [tests]);

  return (
    <div
      className="dashboard-tests"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2>الاختبارات</h2>
          <p>
            في الصفحة القادمة سوف نعرض عليك شروط الاختبار, لاكن اختر الاختبار
            اولا.
          </p>
          <div className="search-box">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="search"
                placeholder="ابحث عن اختبار محدد"
                value={search.search}
                required
                onChange={handleChange}
              />
              <button type="submit">بحث</button>
            </form>
          </div>
        </div>
        <div className="row">
          <div
            className="tests"
            style={{ display: hidden === "none" ? "flex" : "none" }}
          >
            {tests.length > 0
              ? tests.map((test, index) => {
                  return (
                    <Link to={`/test/${test.id}`} key={index}>
                      <div className="test-box">
                        <i className="fa-light fa-pen-to-square"></i>
                        <span>اختبار</span>
                        <p>{test.category}</p>
                      </div>
                    </Link>
                  );
                })
              : `لا يوجد اختبارات ..`}
          </div>
          <div className="tests" style={{ display: hidden }}>
            {searchResult.length > 0
              ? searchResult.map((test, index) => (
                  <Link to={`/test/${test.id}`} key={index}>
                    <div className="test-box">
                      <i className="fa-light fa-pen-to-square"></i>
                      <span>اختبار</span>
                      <p>{test.category}</p>
                    </div>
                  </Link>
                ))
              : `لا يوجد نتائج ..`}
            <br />
            <button onClick={handleClick}>جميع الاختبارت</button>
          </div>
        </div>
      </div>
    </div>
  );
}
