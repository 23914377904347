import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getUsercategories } from "../../../REDUX/Models/answers.Model";
import {
  createCurriculums,
  getUserPublicCurriculums,
  getUserPrivateCurriculums,
  deleteCurriculum,
} from "../../../REDUX/Models/curriculums.Model";
import { signOut, setError } from "./../../../REDUX/Slices/users.Slice";
import Date from "../../../Components/Date";
import "./index.scss";

export default function Curriculums(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const error = useSelector((state) => state.error);
  const [categories, setCategories] = useState([]);
  const [course, setCourse] = useState({
    title: "",
    description: "",
    test: "",
    public: false,
    subscribers: 0,
    category_id: "",
    category: "",
    user_id: userData.id,
  });
  const [privateCourses, setPrivateCourses] = useState([]);
  const [publicCourses, setPublicCourses] = useState([]);
  const [addHidden, setAddHidden] = useState(false);
  const [reviewHidden, setReviewHidden] = useState(true);
  const [workingHidden, setWorkingHidden] = useState(true);
  const [text, setText] = useState();

  const handleChange = (e) => {
    setCourse({ ...course, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleClick = (id) => {
    alert("هل انت متأكد من حذف المنهج");
    deleteCurriculum(id).then();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createCurriculums(course)
      .then(() => {
        setCourse({
          title: "",
          description: "",
          test: "",
          public: false,
          category_id: "",
          user_id: userData.id,
        });
      })
      .catch(() => {
        dispatch(setError("خطأ انشاء المنهج."));
        setTimeout(() => {
          dispatch(setError(null));
        }, 1000);
      });
  };

  const showAdd = () => {
    setAddHidden(false);
    setReviewHidden(true);
    setWorkingHidden(true);
  };
  const showReview = () => {
    setAddHidden(true);
    setReviewHidden(false);
    setWorkingHidden(true);
  };

  const showWorking = () => {
    setAddHidden(true);
    setReviewHidden(true);
    setWorkingHidden(false);
  };

  useEffect(() => {
    getUsercategories(userData.id)
      .then((res) => {
        if (res.data.categories.length > 0) {
          setCategories(res.data.categories);
        } else {
          setText("يجب اجتياز الاختبار اولا.");
        }
      })
      .catch(() => {
        dispatch(signOut());
      });
    dispatch(setError(null));
  }, [categories]);

  useEffect(() => {
    getUserPrivateCurriculums({ user_id: userData.id })
      .then((res) => {
        setPrivateCourses(res.data.curriculums);
      })
      .catch(() => {
        dispatch(signOut());
      });
  }, [privateCourses]);

  useEffect(() => {
    getUserPublicCurriculums({ user_id: userData.id })
      .then((res) => {
        setPublicCourses(res.data.curriculums);
      })
      .catch(() => {
        dispatch(signOut());
      });
  }, [publicCourses]);

  return (
    <div
      className="dashboard-curriculums"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2>المناهج</h2>
        </div>
        <div className="row" style={{ display: text ? "none" : "flex" }}>
          <div className="btns">
            <button onClick={() => showAdd()}>
              <i className="fa-light fa-plus"></i>اضافة منهج
            </button>
            <button onClick={() => showReview()}>
              <i className="fa-light fa-file-magnifying-glass"></i>قيد المراجعة
            </button>
            <button onClick={() => showWorking()}>
              <i className="fa-light fa-coins"></i>مناهج عامة
            </button>
          </div>
          <div className="content">
            <div
              className="add"
              style={{ display: addHidden ? "none" : "block" }}
            >
              <form onSubmit={handleSubmit}>
                <label>اضف اسم المنهج</label>
                <input
                  type="text"
                  name="title"
                  placeholder="مثل: دورة في المحاسبة"
                  required
                  value={course.title}
                  onChange={handleChange}
                />
                <label>تخصص المنهج</label>
                <select name="category_id" onChange={handleChange} required>
                  {categories.map((category, index) => {
                    return (
                      <option value={category.id} key={index}>
                        {category.category}
                      </option>
                    );
                  })}
                </select>
                <label>وصف المنهج</label>
                <textarea
                  name="description"
                  placeholder="المواضيع الاساسية في المنهج"
                  value={course.description}
                  onChange={handleChange}
                  required
                />
                <label>
                  اضف اختبار للمنهج <Link to="/post/1">كيف</Link>
                </label>
                <input
                  type="text"
                  name="test"
                  placeholder="رابط اختبار من جوجل"
                  value={course.test}
                  onChange={handleChange}
                  required
                />
                <button type="submit">ارسال للمراجعة</button>
              </form>
              {error && <span>{error}</span>}
            </div>
            <div
              className="review"
              style={{ display: reviewHidden ? "none" : "block" }}
            >
              {privateCourses.length >= 1
                ? privateCourses.map((privateCourse, index) => {
                    return (
                      <div key={index}>
                        <p>
                          {privateCourse.title} -{" "}
                          <Date date={privateCourse.created_date} />
                        </p>
                        <button onClick={() => handleClick(privateCourse.id)}>
                          <i className="fa-light fa-trash"></i>حذف
                        </button>
                        <hr />
                      </div>
                    );
                  })
                : "لا يوجد مناهج قيد المراجعة"}
            </div>
            <div
              className="review"
              style={{ display: workingHidden ? "none" : "block" }}
            >
              {publicCourses.length >= 1
                ? publicCourses.map((publicCourse, index) => {
                    return (
                      <div key={index}>
                        <Link to={`/curriculum/${publicCourse.id}`}>
                          <p>
                            {publicCourse.title}
                            {`(${publicCourse.subscribers} ناجح)`} -{" "}
                            <Date date={publicCourse.created_date} />
                          </p>
                        </Link>
                        <button onClick={() => handleClick(publicCourse.id)}>
                          <i className="fa-light fa-trash"></i>حذف
                        </button>
                        <hr />
                      </div>
                    );
                  })
                : "لا يوجد مناهج عامة"}
            </div>
          </div>
        </div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
}
