import { useState, useEffect } from "react";
import { getPost, getSidebarPosts } from "../../../REDUX/Models/posts.Model";
import { Link, useNavigate, useParams } from "react-router-dom";
import parser from "html-react-parser";
import Date from "./../../../Components/Date";
import "./index.scss";

export default function Post() {
  const postId = useParams().id;
  const navigate = useNavigate();

  const [post, setPost] = useState({});
  const [sidePosts, setSidePosts] = useState([]);

  useEffect(() => {
    getPost(postId)
      .then((res) => {
        if (res.data.post.title === undefined) {
          navigate("/blog");
        } else {
          setPost(res.data.post);
          getSidebarPosts().then((res) => {
            setSidePosts(res.data.posts);
          });
        }
      })
      .catch(() => {
        navigate("/blog");
      });
  }, [postId]);

  document.title = `طورنى - ${post.title}`;

  return (
    <div className="post">
      <div className="container">
        <div className="content">
          <div className="post-img">
            <img src={post.image} alt="Post Image" />
          </div>
          <h2>{post.title}.</h2>
          <span>
            <Date date={post.created_date} />
          </span>
          {parser(`<p>${post.description}<p>`)}
        </div>
        <div className="sidebar">
          {sidePosts.map((sidePost, index) => {
            return (
              <div className="side-post" key={index}>
                <Link to={`/post/${sidePost.id}`}>
                  <img src={sidePost.image} alt="SideBar Post Image" />
                  <h3>{sidePost.title}</h3>
                </Link>
                <button onClick={() => navigate(`/post/${sidePost.id}`)}>
                  اقرأ اكثر
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
