import { Link } from "react-router-dom";

export default function ProfileSkills(props) {
  return (
    <div className="skill-box">
      <i className="fa-duotone fa-shapes"></i>
      <p>{props.skill.name}</p>
      <span>
        <Link to={`/category/${props.skill.category}`}>
          مهارات {props.skill.category}
        </Link>
      </span>
    </div>
  );
}
