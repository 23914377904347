/*REMOVE ITEMS FROM LOCALSTORAGE*/
export default function removeItems() {
  window.localStorage.removeItem("user_id");
  window.localStorage.removeItem("user_name");
  window.localStorage.removeItem("first_name");
  window.localStorage.removeItem("last_name");
  window.localStorage.removeItem("email");
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("location");
  window.localStorage.removeItem("bio");
  window.localStorage.removeItem("rating");
  window.localStorage.removeItem("facebook");
  window.localStorage.removeItem("twitter");
  window.localStorage.removeItem("whatsapp");
  window.localStorage.removeItem("image");
  window.localStorage.removeItem("verified");
}
