import axios from "axios";
import { token } from "../Storage/getItems";

export const createPost = async (values) => {
  try {
    const response = await axios.post(`/api/posts`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPosts = async () => {
  try {
    const response = await axios.get(`/api/posts`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSidebarPosts = async () => {
  try {
    const response = await axios.get(`/api/posts/side/posts`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPost = async (id) => {
  try {
    const response = await axios.get(`/api/posts/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const searchPosts = async (values) => {
  try {
    const response = await axios.post(`/api/posts/search`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updatePost = async (id, values) => {
  try {
    const response = axios.put(`/api/posts/${id}`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deletePost = async (id) => {
  try {
    const response = await axios.delete(`/api/posts/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
