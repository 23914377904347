import axios from "axios";
import { token } from "./../Storage/getItems";

export const createAnswer = async (values) => {
  try {
    const response = await axios.post(`/api/answers`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCorrectAnswers = async () => {
  try {
    const response = await axios.get(`/api/answers/correct`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUsercategories = async (id) => {
  try {
    const response = await axios.get(`/api/answers/user/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getIncorrectAnswers = async () => {
  try {
    const response = await axios.get(`/api/answers/incorrect`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAnswer = async (values) => {
  try {
    const response = await axios.patch(`/api/answers/update`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteAnswer = async (id) => {
  try {
    const response = await axios.delete(`/api/answers/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
