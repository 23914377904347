import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./../../assets/Logo.svg";
import "./index.scss";

export default function About() {
  document.title = `طورنى - من نحن`;

  const [q1Style, setQ1Style] = useState(false);
  const [q2Style, setQ2Style] = useState(false);
  const [q3Style, setQ3Style] = useState(false);
  const [q4Style, setQ4Style] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="about">
      <div className="container">
        <div className="about-header">
          <h2> مرحبا بكم في منصة طورنى</h2>
          <p>
            منصة طورنى هى المنصة الأولى في الوطن العربي في فكرتها حيث تقوم
            بتوصيل المدرب بـ المتعلم بشكل مجانى 100%.
          </p>
          <button onClick={() => navigate("/register")}>سجل الأن</button>
          <div className="about-img">
            <img src={Logo} alt="Logo" />
          </div>
        </div>
      </div>
      <div className="culture">
        <div className="culture-head">
          <h2> ثقافة طورنى</h2>
          <p>
            تسعى طورنى دائما في مجال العلم حيث ان هدفنا نشر المهارات في الوطن
            العربي.
          </p>
        </div>
        <div className="row">
          <section>
            <h3>حب التعلم</h3>
            <p>حيث اننا في طورنى نحب العلم والتعلم.</p>
          </section>
          <section>
            <h3>تبادل العلم والمهارات</h3>
            <p>تعلم وانشر مهاراتك واحصل على مكافأت قيمة.</p>
          </section>
          <section>
            <h3>مجانى وسيبقي مجانى</h3>
            <p>هدفنا هو نشر المهارات في الوطن العربي.</p>
          </section>
        </div>
      </div>
      <div className="asked">
        <h2> الاسئلة الشائعة</h2>
        <section>
          <div onClick={() => setQ1Style(!q1Style)}>
            <h3>ما هى منصة طورنى</h3>
            <i
              className={
                q1Style
                  ? "fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  : "fa-light fa-arrow-down-left-and-arrow-up-right-to-center"
              }
            ></i>
          </div>
          <p className={q1Style ? "hidden" : ""}>
            طورنى هى منصة تعليمية تساعد على تبادل العلم و المهارات في الوطن
            العربي.
          </p>
          <hr />
        </section>
        <section>
          <div onClick={() => setQ2Style(!q2Style)}>
            <h3>ما هو هدف طورنى</h3>
            <i
              className={
                q2Style
                  ? "fa-light fa-arrow-down-left-and-arrow-up-right-to-center"
                  : "fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
              }
            ></i>
          </div>
          <p className={q2Style ? "" : "hidden"}>
            تسعى طورنى في مجال التعلم دائما وهدفنا هو نشر العلم بشكل مجانى.
          </p>
          <hr />
        </section>
        <section>
          <div onClick={() => setQ3Style(!q3Style)}>
            <h3>ما هى مكافأت طورنى</h3>
            <i
              className={
                q3Style
                  ? "fa-light fa-arrow-down-left-and-arrow-up-right-to-center"
                  : "fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
              }
            ></i>
          </div>
          <p className={q3Style ? "" : "hidden"}>
            تقدم طورنى جوائز قيمة لتساعد المدرب والمتعلم مثل رحلات حج وعمرة
            وجوائز مالية واكثر.
          </p>
          <hr />
        </section>
        <section>
          <div onClick={() => setQ4Style(!q4Style)}>
            <h3>كيف ابدأ في طورنى</h3>
            <i
              className={
                q4Style
                  ? "fa-light fa-arrow-down-left-and-arrow-up-right-to-center"
                  : "fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
              }
            ></i>
          </div>
          <p className={q4Style ? "" : "hidden"}>
            تقدم طورنى لوحة تحكم سهلة الاستخدام لتساعدك في نشر مهاراتك وتذكر
            دائما .. خيركم من تعلم العلم وعلمة.
          </p>
          <hr />
        </section>
      </div>
    </div>
  );
}
