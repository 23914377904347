import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signOut, setError } from "./../../../REDUX/Slices/users.Slice";
import {
  getFriends,
  deleteFriend,
} from "./../../../REDUX/Models/friends.Model";
import { searchUser } from "../../../REDUX/Models/users.Model";
import { Link } from "react-router-dom";
import avatar from "./../../../assets/icons/avatar.svg";
import "./index.scss";

export default function Friends(props) {
  const [friends, setFriends] = useState([]);
  const [search, setSearch] = useState({ user_name: "" });
  const [searchResult, setSearchResult] = useState([]);
  const [hidden, setHidden] = useState("none");

  const user_id = useSelector((state) => state.userData.id);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearch({ user_name: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchUser(search)
      .then((res) => {
        setSearchResult(res.data.users);
      })
      .catch(() => {
        dispatch(signOut());
      });
    setHidden("flex");
  };

  const handleClick = () => {
    setSearchResult([]);
    setSearch({ user_name: "" });
    setHidden("none");
  };

  const handleDelete = (id) => {
    alert("هل انت متأكد من حذف الصداقة");
    deleteFriend({ user_id_to: id, user_id_from: user_id }).then(
      setTimeout(() => {
        dispatch(setError(null));
      }, 1000),
    );
    dispatch(setError("تم حذف الصداقة"));
  };

  useEffect(() => {
    getFriends(user_id)
      .then((res) => {
        setFriends(res.data.friends);
      })
      .catch(() => {
        dispatch(signOut());
      });
    dispatch(setError(null));
  }, [friends]);

  return (
    <div
      className="dashboard-friends"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2>الاصدقاء</h2>
          <div className="search-box">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="search"
                placeholder="ابحث عن شخص محدد"
                value={search.user_name}
                required
                onChange={handleChange}
              />
              <button type="submit">بحث</button>
            </form>
          </div>
        </div>
        <div className="row">
          <div
            className="friends"
            style={{ display: hidden === "none" ? "flex" : "none" }}
          >
            {friends.length > 0 ? (
              friends.map((friend, index) => {
                return (
                  <div className="friend-box" key={index}>
                    <Link to={`/profile/${friend.id}`}>
                      <div className="friend-img">
                        <img
                          src={friend.image == null ? avatar : friend.image}
                          alt="Friend Profile Picture"
                        />
                      </div>
                    </Link>
                    <Link to={`/profile/${friend.id}`}>
                      <div className="friend-name">
                        <p>
                          {friend.first_name} {friend.last_name}
                        </p>
                      </div>
                    </Link>
                    <div className="social-links">
                      <Link to={friend.facebook} target="_blank">
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                      <Link to={friend.twitter} target="_blank">
                        <i className="fa-brands fa-twitter"></i>
                      </Link>
                      <Link to={friend.whatsapp} target="_blank">
                        <i className="fa-brands fa-whatsapp"></i>
                      </Link>
                    </div>
                    <span onClick={() => handleDelete(friend.id)}>
                      <i className={`fa-light fa-trash`}></i>
                      <p>حذف</p>
                    </span>
                  </div>
                );
              })
            ) : (
              <span className="span">لا يوجد اصدقاء للعرض ..</span>
            )}
            {error && <span>{error}</span>}
          </div>
          <div className="friends" style={{ display: hidden }}>
            {searchResult
              ? searchResult.map((friend, index) => {
                  return (
                    <div className="friend-box" key={index}>
                      <Link to={`/profile/${el.id}`}>
                        <div className="friend-img">
                          <img
                            src={friend.image == null ? avatar : friend.image}
                            alt="Friend Profile Picture"
                          />
                        </div>
                        <div className="friend-name">
                          <p>
                            {friend.first_name} {friend.last_name}
                          </p>
                        </div>
                      </Link>
                      <div className="social-links">
                        <Link to={friend.facebook}>
                          <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link to={friend.twitter}>
                          <i className="fa-brands fa-twitter"></i>
                        </Link>
                        <Link to={friend.whatsapp}>
                          <i className="fa-brands fa-whatsapp"></i>
                        </Link>
                      </div>
                    </div>
                  );
                })
              : "لا يوجد نتائج .."}
            <button onClick={handleClick}>عرض الاصدقاء</button>
          </div>
        </div>
      </div>
    </div>
  );
}
