import { useEffect } from "react";
import avatar from "./../../../assets/icons/avatar.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut, setError } from "../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Profile(props) {
  const { userData } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData.id == null) {
      dispatch(signOut());
    }
    dispatch(setError(null));
  }, [userData]);

  return (
    <div
      className="dashboard-profile"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <h2 className="header">المعلومات</h2>
        <div className="row">
          <div className="profile-img">
            <img src={avatar} alt="Profile Image" />
          </div>
          <div className="profile-info">
            <div className="info">
              <div className="info-header">
                <h2>
                  <Link to={`/profile/${userData.id}`}>
                    {userData.first_name} {userData.last_name}
                  </Link>
                </h2>
                <button onClick={props.function}>تعديل</button>
              </div>
              <i className="fa-light fa-at"></i>
              <p>{userData.user_name}</p>
            </div>
            <div className="location">
              <i className="fa-light fa-location-dot"></i>
              <p>
                {userData.location ? (
                  userData.location
                ) : (
                  <span>اضف مدينتك</span>
                )}
              </p>
            </div>
            <div className="rating">
              <i className="fa-solid fa-star"></i>
              <p>
                {userData.rating ? (
                  <span>{userData.rating + " نجوم"}</span>
                ) : (
                  <span>لا يوجد تقيم</span>
                )}
              </p>
            </div>
            <div className="bio">
              <i className="fa-solid fa-hashtag"></i>
              <p>
                {userData.bio ? userData.bio : <span>اضف نبذة تعريفية</span>}
              </p>
            </div>
          </div>
        </div>
        <div className="user-contact">
          {userData.facebook ? (
            <Link to={userData.facebook} target="_blank">
              <div className="social-box">
                <p>Facebook</p>
                <i className="fa-brands fa-facebook-f"></i>
              </div>
            </Link>
          ) : (
            "اضف معلومات التواصل"
          )}
          {userData.twitter ? (
            <Link to={userData.twitter} target="_blank">
              <div className="social-box">
                <p>Twitter</p>
                <i className="fa-brands fa-twitter"></i>
              </div>
            </Link>
          ) : (
            ""
          )}
          {userData.whatsapp ? (
            <Link to={`https://wa.me/${userData.whatsapp}`} target="_blank">
              <div className="social-box">
                <p>Whatsapp</p>
                <i className="fa-brands fa-whatsapp"></i>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
