import { Link } from "react-router-dom";
import "./index.scss";

export default function Policy() {
  document.title = `طورنى - سياسة المنصة`;
  return (
    <div className="policy">
      <div className="container">
        <h2> سياسة منصة طورنى </h2>
        <p>
          عند استخدام خدماتنا، فإنك تأتمننا على بياناتك. نحن ندرك أن هذه مسؤولية
          كبيرة ونعمل بجدية لحماية هذة المعلومات ونمنحك التحكم فيها عن طريقة
          تعديل او حذف بياناتك كاملة. <br />
          <br />
          تهدف سياسةُ الخصوصية هذه إلى مساعدتك على فهم ماهى المعلومات التي
          نجمعها وسبب جمعنا لها، وكذلك طريقة تحديث معلوماتك, عرضها وحذفها.
        </p>
        <p>
          يمكنك استخدام خدماتنا بطرق شتى لإدارة خصوصيتك. على سبيل المثال، يمكنك
          الاشتراك في حساب منصة طورنى إذا أردت إنشاء محتوى وإدارته، مثل بث
          المعلومات والتواصل مع الاصدقاء في المنصة واضافة المهارات في حسابك
          الشخصي وانشاء المناهج التقنية والغير تقنية، أو إذا أردت عرض مزيد من
          نتائج البحث ذات الصلة. يمكنك تسجيل حساب جديد{" "}
          <Link to="/register">من هنا</Link> <br /> <br />
          يمكنك أيضًا استخدام الكثير من خدماتنا بدون تسجيل الدخول إلى حسابك أو
          بدون إنشاء حساب من الأساس، مثل البحث عن التخصصات, تصفح المناهج
          والتدوينات او البحث عن اشخاص ذو مهارة محددة.
          <br />
          <br />
          وتشمل المعلومات التى نجمها منك ما يلي:
          <br />
          <br />
          <ul>
            <li>الاسم الاول</li>
            <li>الاسم الاخير</li>
            <li>البريد الالكترونى</li>
            <li>الصورة الشخصية (غير الزامى)</li>
          </ul>
        </p>
      </div>
    </div>
  );
}
