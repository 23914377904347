import { useState } from "react";
import { useDispatch } from "react-redux";
import { addPassword } from "../../REDUX/Slices/users.Slice";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./../../assets/Logo.svg";
import "./index.scss";

export default function Admin() {
  const [password, setPassword] = useState("");
  const [icon, setIcon] = useState("fa-solid fa-lock");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password == process.env.REACT_APP_ADM_PASS) {
      dispatch(addPassword({ password: password }));
      navigate("/admin/adm");
    } else if (password == process.env.REACT_APP_PUB_PASS) {
      dispatch(addPassword({ password: password }));
      navigate("/admin/pub");
    } else if (password == process.env.REACT_APP_COR_PASS) {
      dispatch(addPassword({ password: password }));
      navigate("/admin/cor");
    } else if (password == process.env.REACT_APP_DOC_PASS) {
      dispatch(addPassword({ password: password }));
      navigate("/admin/doc");
    } else if (password == process.env.REACT_APP_REV_PASS) {
      dispatch(addPassword({ password: password }));
      navigate("/admin/rev");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="admin">
      <div className="container">
        <div className="row">
          <div className="password">
            <Link to="/">
              <img src={Logo} alt="Twrny Logo" />
            </Link>
            <form onSubmit={handleSubmit}>
              <label
                onClick={() => setIcon("fa-solid fa-lock-open")}
                onDoubleClick={() => setIcon("fa-solid fa-lock")}
              >
                <i className={icon}></i>
              </label>
              <input
                type={icon == "fa-solid fa-lock" ? "password" : "text"}
                placeholder="كلمة السر"
                required
                onChange={handleChange}
              />
              <button type="submit">دخول</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
