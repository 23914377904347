import { useState } from "react";
import Cats from "./../../Components/Cats";
import Posts from "./../../Components/Posts";
import Answers from "./../../Components/Answers";
import Curriculums from "./../../Components/Curriculums";
import Tests from "./../../Components/Tests";
import Ads from "./../../Components/Ads";
import { useDispatch } from "react-redux";
import { removePassword } from "./../../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function ADM() {
  const [content, setContent] = useState();
  const dispatch = useDispatch();

  const showCats = () => {
    setContent(<Cats />);
  };
  const showPosts = () => {
    setContent(<Posts />);
  };
  const showAnswers = () => {
    setContent(<Answers />);
  };
  const showCurriculums = () => {
    setContent(<Curriculums />);
  };
  const showTests = () => {
    setContent(<Tests />);
  };
  const showAds = () => {
    setContent(<Ads />);
  };

  return (
    <>
      <div className="adm">
        <div className="container">
          <header>
            <h2> لوحة تحكم المدير </h2>
            <p> اهلا بك من جديد..</p>
          </header>
          <div className="row">
            <div className="navigator">
              <div className="nav-box" onClick={() => showPosts()}>
                <span>
                  <i className="fa-duotone fa-typewriter"></i>
                </span>
                <p>التدوينات</p>
              </div>
              <div className="nav-box" onClick={() => showCurriculums()}>
                <span>
                  <i className="fa-duotone fa-graduation-cap"></i>
                </span>
                <p>المناهج</p>
              </div>
              <div className="nav-box" onClick={() => showTests()}>
                <span>
                  <i className="fa-duotone fa-pen-field"></i>
                </span>
                <p>الاختبارات</p>
              </div>
              <div className="nav-box" onClick={() => showAnswers()}>
                <span>
                  <i className="fa-duotone fa-badge-check"></i>
                </span>
                <p>الاجابات</p>
              </div>
              <div className="nav-box" onClick={() => showCats()}>
                <span>
                  <i className="fa-duotone fa-folder"></i>
                </span>
                <p>التخصصات</p>
              </div>
              <div className="nav-box" onClick={() => showAds()}>
                <span>
                  <i className="fa-duotone fa-sack-dollar"></i>
                </span>
                <p>الاعلانات</p>
              </div>
            </div>
          </div>
          <div className="btn">
            <button onClick={() => dispatch(removePassword())}>خروج</button>
          </div>
        </div>
      </div>
      {content}
    </>
  );
}
