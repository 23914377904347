import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "./../../REDUX/Models/users.Model";
import { addUser, signIn, setError } from "./../../REDUX/Slices/users.Slice";
import { useSelector, useDispatch } from "react-redux";
import register from "./../../assets/icons/register.svg";
import "./index.scss";

export default function Register() {
  document.title = `طورنى - تسجيل حساب جديد`;

  const [user, setUser] = useState({
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const err = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createUser(user)
      .then((res) => {
        dispatch(
          addUser({
            response: res.data.user.response,
            token: res.data.user.token,
          }),
        );
        dispatch(signIn());
        navigate("/loading");
      })
      .catch(() => {
        dispatch(
          setError(
            `اسم المستخدم او البريد الالكترونى موجود مسبقا .. حاول مرة اخرى!`,
          ),
        );
      });
  };

  useEffect(() => {
    dispatch(setError(null));
  }, []);

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="register-img">
            <img src={register} alt="Register" />
          </div>
          <div className="register-form">
            <h2>تسجيل حساب جديد</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="name"
                name="first_name"
                placeholder="الاسم الأول"
                required
                onChange={handleChange}
              />
              <input
                type="name"
                name="last_name"
                placeholder="الاسم الأخير"
                required
                onChange={handleChange}
              />
              <input
                type="name"
                name="user_name"
                placeholder="اسم المستخدم"
                required
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder="البريد الالكترونى"
                required
                onChange={handleChange}
              />
              <input
                type="password"
                name="password"
                placeholder="كلمة السر"
                required
                onChange={handleChange}
              />
              <button type="submit">تسجيل</button>
            </form>
            <div className="links">
              <Link to="/login">او يمكنك تسجيل الدخول</Link>
              {err && <span>{err}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
