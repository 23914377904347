/*GET ITEMS FROM LOCALSTORAGE*/
export const id = JSON.parse(localStorage.getItem("user_id"));
export const user_name = JSON.parse(localStorage.getItem("user_name"));
export const first_name = JSON.parse(localStorage.getItem("first_name"));
export const last_name = JSON.parse(localStorage.getItem("last_name"));
export const email = JSON.parse(localStorage.getItem("email"));
export const token = JSON.parse(localStorage.getItem("token"));
export const location = JSON.parse(localStorage.getItem("location"));
export const bio = JSON.parse(localStorage.getItem("bio"));
export const rating = JSON.parse(localStorage.getItem("rating"));
export const facebook = JSON.parse(localStorage.getItem("facebook"));
export const twitter = JSON.parse(localStorage.getItem("twitter"));
export const whatsapp = JSON.parse(localStorage.getItem("whatsapp"));
export const image = JSON.parse(localStorage.getItem("image"));
export const verified = JSON.parse(localStorage.getItem("verified"));
