import { useState, useEffect } from "react";
import Date from "./../../Components/Date";
import { getPosts, searchPosts } from "./../../REDUX/Models/posts.Model";
import { Link, useNavigate } from "react-router-dom";
import parser from "html-react-parser";
import blog from "./../../assets/icons/blog.svg";
import "./index.scss";

export default function Blog() {
  document.title = `طورنى - المدونة`;

  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState({ title: "" });
  const [searchResult, setSearchResult] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch({ title: e.target.value });
    searchPosts(search).then((res) => {
      setSearchResult(res.data.posts);
    });
  };

  useEffect(() => {
    getPosts()
      .then((res) => {
        setPosts(res.data.posts);
      })
      .catch(() => {
        navigate("/");
      });
  }, [posts]);

  return (
    <div className="blog">
      <div className="container">
        <div className="blog-header">
          <div className="main-text">
            <h2>
              <span>مدونة</span> طورنى
            </h2>
            <p>
              اعرف اكثر عن طورنى وتابع اخبار المنصة اول بـاول من خلال التدوينات
              اليومية.
            </p>
          </div>
          <div className="img">
            <img src={blog} alt="Blog Image" />
          </div>
        </div>
        <div className="search-box">
          <form>
            <input
              type="text"
              name="search"
              placeholder={`ابحث عن تدوينات بين اكثر من ${posts.length} تدوينة`}
              value={search.title}
              required
              onChange={handleChange}
            />
            <label>
              <i className="fa-light fa-magnifying-glass"></i>
            </label>
          </form>
          <div
            className="search"
            style={{ display: search.title.length > 0 ? "block" : "none" }}
          >
            {searchResult.map((search, index) => {
              return (
                <div className="dropdown" key={index}>
                  <Link to={`/post/${search.id}`}>
                    <div className="search-result">
                      <h3>{search.title}</h3>
                      <img src={search.image} alt="Search Post Image" />
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="posts-row">
            {posts.length > 0
              ? posts.map((post, index) => {
                  return (
                    <Link to={`/post/${post.id}`} key={index}>
                      <div className="post-box">
                        <div className="post-image">
                          <img src={post.image} alt="Post Image" />
                        </div>
                        <h3 className="post-title">{post.title}</h3>
                        <p className="post-description">
                          {parser(`${post.description}`)}
                        </p>
                        <span className="post-date">
                          <Date date={post.created_date} />
                        </span>
                      </div>
                    </Link>
                  );
                })
              : `لا يوجد تدوينات ..`}
          </div>
        </div>
      </div>
    </div>
  );
}
