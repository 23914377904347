import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "./../../assets/Header.svg";
import "./main-theme.scss";
import "./white-theme.scss";
import "./responsive.scss";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isSigned = useSelector((state) => state.isSigned);
  const { first_name } = useSelector((state) => state.userData);
  const [hidden, setHidden] = useState();
  const [bgclass, setBgClass] = useState();
  const [menuclass, setMenuClass] = useState();

  const handleClick = () => {
    if (hidden == "none") {
      setHidden("flex");
    } else {
      setHidden("none");
    }
  };

  useEffect(() => {
    if (location.pathname !== "/contact") {
      setBgClass("container");
      setMenuClass("menu");
      setHidden("flex");
    } else {
      setBgClass("container-white");
      setMenuClass("menu-white");
    }
    setHidden("none");
  }, [location]);

  return (
    <div className="header">
      <div className={bgclass}>
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Twrny Logo" />
          </Link>
        </div>
        <i
          className={hidden == "flex" ? "fa-solid fa-x" : "fa-solid fa-compass"}
          onClick={handleClick}
        ></i>
        <div className={menuclass} style={{ display: hidden }}>
          <NavLink to="/">
            <p>الرئيسية</p>
          </NavLink>
          <NavLink to="/about">
            <p>من نحن</p>
          </NavLink>
          <NavLink to="/categories">
            <p>التخصصات</p>
          </NavLink>
          <NavLink to="/curriculums">
            <p>المناهج</p>
          </NavLink>
          <NavLink to="/blog">
            <p>المدونة</p>
          </NavLink>
          <NavLink to="/contact">
            <p>تواصل معنا</p>
          </NavLink>
        </div>
        <div className="btn">
          {isSigned ? (
            <Link to="/dashboard">
              <span>
                <i className="fa-light fa-user"></i>
                {first_name.toUpperCase()}
              </span>
            </Link>
          ) : (
            <button onClick={() => navigate("/login")}>تسجيل دخول</button>
          )}
        </div>
      </div>
    </div>
  );
}
