import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Terms from "./Pages/Terms";
import Policy from "./Pages/Policy";
import Contact from "./Pages/Contact";
import Categories from "./Pages/Categories";
import Category from "./Pages/Category";
import Curriculums from "./Pages/Curriculums";
import Curriculum from "./Pages/Curriculum";
import Profile from "./Pages/Profile";
import View from "./Pages/Profile/View";
import Blog from "./Pages/Blog";
import Post from "./Pages/Blog/Post";
import Test from "./Pages/Test";
import Verify from "./Pages/Verify";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import Admin from "./Pages/Admin";
import ADM from "./Pages/Admin/Pages/ADM";
import PUB from "./Pages/Admin/Pages/PUB";
import COR from "./Pages/Admin/Pages/COR";
import DOC from "./Pages/Admin/Pages/DOC";
import REV from "./Pages/Admin/Pages/REV";
import Loading from "./Components/Loading";
import Verification from "./Components/Verify";
import ErrorPage from "./Pages/404";

export default function App() {
  const isSigned = useSelector((state) => state.isSigned);
  const password = useSelector((state) => state.password);
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/curriculums" element={<Curriculums />} />
          <Route
            path="/curriculum/:id"
            element={isSigned ? <Curriculum /> : <Navigate to="/login" />}
          />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/profile/view/:id" element={<View />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/verify/:id" element={<Verify />} />
          <Route
            path="/users/verify/:id"
            element={!isSigned ? <Navigate to="/login" /> : <Verification />}
          />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/loading" element={<Loading />} />
        <Route
          path="/admin"
          element={!isSigned ? <Navigate to="/login" /> : <Admin />}
        />
        <Route
          path="/admin/adm"
          element={password ? <ADM /> : <Navigate to="/admin" />}
        />
        <Route
          path="/admin/pub"
          element={password ? <PUB /> : <Navigate to="/admin" />}
        />
        <Route
          path="/admin/cor"
          element={password ? <COR /> : <Navigate to="/admin" />}
        />
        <Route
          path="/admin/doc"
          element={password ? <DOC /> : <Navigate to="/admin" />}
        />
        <Route
          path="/admin/rev"
          element={password ? <REV /> : <Navigate to="/admin" />}
        />
        <Route
          path="/login"
          element={isSigned ? <Navigate to="/loading" /> : <Login />}
        />
        <Route
          path="/register"
          element={isSigned ? <Navigate to="/loading" /> : <Register />}
        />
        <Route
          path="/dashboard"
          element={!isSigned ? <Navigate to="/login" /> : <Dashboard />}
        />
        <Route
          path="/test/:id"
          element={!isSigned ? <Navigate to="/login" /> : <Test />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}
