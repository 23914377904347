import { Link } from "react-router-dom";
import avatar from "./../../../assets/icons/avatar.svg";

export default function ProfileFriends(props) {
  return (
    <div className="friend-box">
      <Link to={`/profile/${props.friend.id}`}>
        <div className="friend-img">
          <img
            src={props.friend.image == null ? avatar : props.friend.image}
            alt="Friend Profile Picture"
          />
        </div>
      </Link>
      <Link to={`/profile/${props.friend.id}`}>
        <div className="friend-name">
          <p>
            {props.friend.first_name} {props.friend.last_name}
          </p>
        </div>
      </Link>
      <div className="social-links">
        <Link to={props.friend.facebook} target="_blank">
          <i className="fa-brands fa-facebook-f"></i>
        </Link>
        <Link to={props.friend.twitter} target="_blank">
          <i className="fa-brands fa-twitter"></i>
        </Link>
        <Link to={props.friend.whatsapp} target="_blank">
          <i className="fa-brands fa-whatsapp"></i>
        </Link>
      </div>
    </div>
  );
}
