import { useState, useEffect } from "react";
import { getTrainer } from "../../REDUX/Models/skills.Model";
import { Link, useParams, useNavigate } from "react-router-dom";
import avatar from "./../../assets/icons/avatar.svg";
import search from "./../../assets/imgs/search.png";
import "./index.scss";

export default function Category() {
  const category_name = useParams().id;
  document.title = `طورنى - مهارات ${category_name}`;

  const [users, setUsers] = useState([{}]);

  const navigate = useNavigate();

  useEffect(() => {
    getTrainer({ category: category_name })
      .then((res) => {
        setUsers(res.data.trainers);
      })
      .catch(() => {
        alert("خطأ عرض الاصدقاء");
      });
  }, []);

  return (
    <div className="category">
      <div className="container">
        <div className="cat-header">
          <div className="main-text">
            <h2>اشخاص لديهم مهارات {category_name}</h2>
            <p>يمكنك ارسال طلب صداقة للتواصل مع صاحب المهارة.</p>
            <span>
              <i className="fa-light fa-user"></i>
              {" +" + users.length + " مدرب"}
            </span>
          </div>
          <div className="img">
            <img src={search} alt="Search Category Image" />
          </div>
        </div>
        <div className="row">
          <h2>مهارات {category_name}</h2>
          <div className="friends-row">
            {users.length > 0
              ? users.map((user, index) => {
                  return (
                    <Link to={`/profile/${user.user_id}`} key={index}>
                      <div className="friend-box">
                        <div className="friend-info">
                          <div className="friend-header">
                            <div className="img">
                              <img
                                src={user.image !== null ? user.image : avatar}
                                alt="User Image"
                              />
                            </div>
                            <div className="name">
                              <p>
                                {user.first_name} {user.last_name}
                              </p>
                            </div>
                          </div>
                          <div className="icons">
                            <i className="fa-solid fa-star"></i>
                            <p>
                              {user.rating
                                ? user.rating + " نجوم"
                                : "لايوجد تقيم"}
                            </p>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>
                              {user.location ? user.location : "لا يوجد مدينة"}
                            </p>
                          </div>
                          <div className="bio">
                            <p>
                              {user.bio ? user.bio : "لا يوجد نبذة تعريفية"}
                            </p>
                          </div>
                          <div className="friend-skills">
                            <p>
                              المهارات: <span>{user.name}</span>
                              <span
                                onClick={() =>
                                  navigate(`/profile/${user.user_id}`)
                                }
                              >
                                + اكثر
                              </span>
                            </p>
                          </div>
                        </div>
                        <span>الصفحة الشخصية</span>
                      </div>
                    </Link>
                  );
                })
              : `لا يوجد اشخاص لهذة المهارة ..`}
          </div>
        </div>
      </div>
    </div>
  );
}
