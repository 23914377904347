import { useState } from "react";
import Logo from "./../../assets/Header.svg";
import { Link } from "react-router-dom";
import "./index.scss";

export default function Footer() {
  const date = new Date().getFullYear();
  const [input, setInput] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setInput("");
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="news-letter">
          <h3> اشترك في نشرتنا البريدية</h3>
          <form onSubmit={handleSubmit}>
            <input
              name="news-letter"
              placeholder="بريدك الالكترونى .."
              required
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button type="submit">
              <i className="fa-light fa-paper-plane"></i>
            </button>
          </form>
        </div>
        <div className="navigation">
          <Link to="/">
            <p>الرئيسية</p>
          </Link>
          <Link to="/about">
            <p>من نحن</p>
          </Link>
          <Link to="/terms">
            <p>طريقة الاستخدام</p>
          </Link>
          <Link to="/policy">
            <p>سياسة طورنى</p>
          </Link>
          <Link to="/contact">
            <p>تواصل معنا</p>
          </Link>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Trwny Logo" />
          </Link>
        </div>
        <div className="copyrights">
          <p>
            جميع الحقوق محفوظة &copy; <span>{date}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
