import { useState } from "react";
import { contact } from "./../../REDUX/Models/users.Model";
import "./index.scss";

export default function Contact() {
  document.title = `طورنى - تواصل معنا`;

  const [inputs, setInputs] = useState({ name: "", email: "", message: "" });
  const [status, setStatus] = useState(
    <i className="fa-light fa-paper-plane"></i>,
  );

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    contact({
      subject: inputs.name,
      email: inputs.email,
      message: inputs.message,
    })
      .then(
        setTimeout(() => {
          setStatus("تم الارسال بنجاح");
          setInputs({ name: "", email: "", message: "" });
        }, 500),
        setStatus("جارى الارسال .."),
      )
      .catch(() => {
        alert("خطأ ارسال الرسالة .. اعد المحاولة");
      });
  };

  return (
    <div className="contact">
      <div className="container">
        <h2>لا تتردد في التواصل.</h2>
        <div className="row">
          <div className="form">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="الاسم"
                value={inputs.name}
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="email"
                placeholder="البريد الالكترونى"
                value={inputs.email}
                required
                onChange={handleChange}
              />
              <textarea
                name="message"
                placeholder="ادخل رسالتك"
                value={inputs.message}
                required
                onChange={handleChange}
              />
              <button type="submit">{status}</button>
            </form>
          </div>
          <div className="text">
            <h3> معلومات التواصل</h3>
            <p> رقم الهاتف: <span dir="ltr">+971 55 102 9555</span></p>
            <p> العنوان: الامارات العربية المتحدة </p>
            <p>البريد الالكترونى: Tawarny17@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}
