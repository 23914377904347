import axios from "axios";

export const createCertificate = async (values) => {
  try {
    const response = await axios.post("/api/certificates", values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserCertificates = async (id) => {
  try {
    const response = await axios.get(`/api/certificates/user/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCertificate = async (id) => {
  try {
    const response = await axios.get(`/api/certificates/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const downloadCertificate = async (values) => {
  try {
    const response = await axios.post(
      "/api/certificates/download/certificate",
      values,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCertificate = async (id) => {
  try {
    const response = await axios.delete(`/api/certificates/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
