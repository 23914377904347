import { useState, useEffect } from "react";
import {
  getTests,
  createTest,
  deleteTest,
} from "./../../../../REDUX/Models/tests.Model";
import { getCategories } from "./../../../../REDUX/Models/skills.Model";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Tests() {
  const [tests, setTests] = useState([]);
  const [categories, setCategories] = useState([]);
  const [test, setTest] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    category_id: "",
  });
  const [text, setText] = useState("انشاء");
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setTest({ ...test, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createTest(test)
      .then(
        setTimeout(() => {
          setText("انشاء");
        }, 1000),
        setText("تم الانشاء"),
        setTest({
          q1: "",
          q2: "",
          q3: "",
          q4: "",
          q5: "",
          category_id: "",
        }),
      )
      .catch(() => {
        dispatch(signOut());
      });
  };

  const handleDelete = (id) => {
    deleteTest(id).then();
  };

  useEffect(() => {
    getTests()
      .then((res) => {
        setTests(res.data.tests);
      })
      .catch(() => {
        dispatch(signOut());
      });
    getCategories().then((res) => setCategories(res.data.categories));
  }, [tests]);

  return (
    <div className="admin-tests" style={{ display: hidden ? "none" : "block" }}>
      <div className="container">
        <header>
          <h2>الاختبارات</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <p>
          * يجب اختيار التخصص في كل مرة يتم اضافة اختبار ..
          <span>عدد الاختبارات: {tests.length}</span>
        </p>
        <div className="row">
          <div className="add-test">
            <form onSubmit={handleSubmit}>
              <label>التخصص</label>
              <select name="category_id" required onChange={handleChange}>
                <option>--</option>
                {categories.map((category, index) => (
                  <option value={category.id} key={index}>
                    {category.category}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="q1"
                value={test.q1}
                placeholder="السؤال الأول"
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="q2"
                value={test.q2}
                placeholder="السؤال الثانى"
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="q3"
                value={test.q3}
                placeholder="السؤال الثالث"
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="q4"
                value={test.q4}
                placeholder="السؤال الرابع"
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="q5"
                value={test.q5}
                placeholder="السؤال الخامس"
                required
                onChange={handleChange}
              />
              <button type="submit">{text}</button>
            </form>
          </div>
          <div className="view-tests">
            {tests.map((el, index) => {
              return (
                <div className="test-box" key={index}>
                  <p>{el.q1}</p>
                  <p>{el.q2}</p>
                  <p>{el.q3}</p>
                  <p>{el.q4}</p>
                  <p>{el.q5}</p>
                  <i
                    className="fa-light fa-trash"
                    onClick={() => handleDelete(el.id)}
                  ></i>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
