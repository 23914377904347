import axios from "axios";
import { token } from "./../Storage/getItems";

export const addFriend = async (id, values) => {
  try {
    const response = await axios.post(`/api/friends/${id}`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getFriends = async (id) => {
  try {
    const response = await axios.get(`/api/friends/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const checkFriends = async (id, values) => {
  try {
    const response = await axios.post(`/api/friends/check/${id}`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteFriend = async (values) => {
  try {
    const response = await axios.delete(`/api/friends`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
