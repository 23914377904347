import { useState, useEffect } from "react";
import {
  createSkill,
  getCategories,
  getSkills,
  deleteSkill,
} from "./../../../REDUX/Models/skills.Model";
import { useSelector, useDispatch } from "react-redux";
import { signOut, setError } from "./../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Skills(props) {
  const [createdSkill, setCreatedSkill] = useState({
    name: "",
    category: "",
    cat_en: "",
  });
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);

  const dispatch = useDispatch();
  const id = useSelector((state) => state.userData.id);
  const verified = useSelector((state) => state.userData.verified);
  const error = useSelector((state) => state.error);

  const handleChange = (e) => {
    setCreatedSkill({
      ...createdSkill,
      [e.target.name]: e.target.value.toLowerCase(),
      user_id: id,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSkill(createdSkill)
      .then(setCreatedSkill({ name: "", category: "", cat_en: "" }))
      .catch(() => {
        dispatch(signOut());
      });
  };

  const handleDelete = (id) => {
    deleteSkill(id).then(
      setTimeout(() => {
        dispatch(setError(null));
      }, 1000),
    );
    dispatch(setError("تم حذف المهارة"));
  };

  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch(() => {
        dispatch(setError(" خطأ عرض التخصصات"));
      });
    getSkills(id)
      .then((res) => {
        setSkills(res.data.skills);
      })
      .catch(() => {
        dispatch(setError("خطأ عرض المهارات"));
        dispatch(signOut());
      });
  }, [skills]);

  return (
    <div
      className="dashboard-skills"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2>المهارات</h2>
        </div>
        <div className="row">
          <div className="add-skill">
            {verified == true ? (
              <>
                <h3>اضف مهاراة جديدة</h3>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="اضف مهاراة جديدة"
                    value={createdSkill.name}
                    required
                    onChange={handleChange}
                  />
                  <label>التخصص</label>
                  <select name="category" required onClick={handleChange}>
                    {categories.map((category, index) => (
                      <option value={category.category} key={index}>
                        {category.category}
                      </option>
                    ))}
                  </select>
                  <button type="submit">اضافة</button>
                </form>
              </>
            ) : (
              <span>يجب تفعيل حسابك اولا.</span>
            )}
          </div>
          <div className="skills">
            {skills.length > 0 ? (
              skills.map((skill, index) => {
                return (
                  <div className="skill-box" key={index}>
                    <i className="fa-duotone fa-shapes"></i>
                    <p>{skill.name}</p>
                    <span onClick={() => handleDelete(skill.id)}>
                      <i className="fa-light fa-trash"></i>
                      <p>حذف</p>
                    </span>
                  </div>
                );
              })
            ) : (
              <span>{verified == true ? "لا يوجد مهارات للعرض .." : ""}</span>
            )}
          </div>
          {error && <span>{error}</span>}
        </div>
      </div>
    </div>
  );
}
