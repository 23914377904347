import { useState, useEffect } from "react";
import { getCurriculum } from "./../../REDUX/Models/curriculums.Model";
import { getSkill } from "./../../REDUX/Models/skills.Model";
import { useParams, Link, useNavigate } from "react-router-dom";
import Date from "./../../Components/Date";
import avatar from "./../../assets/icons/avatar.svg";
import "./index.scss";

export default function Curriculum() {
  const id = useParams().id;
  const navigate = useNavigate();

  const [curriculum, setCurriculum] = useState({});
  const [skill, setSkill] = useState({});

  useEffect(() => {
    getCurriculum(id)
      .then((res) => {
        setCurriculum(res.data.curriculum);
        getSkill(res.data.curriculum.category_id).then((res) => {
          setSkill(res.data.skill);
        });
      })
      .catch(() => {
        navigate("/");
      });
  }, []);

  document.title = `طورنى - ${curriculum.title}`;
  return (
    <div className="curriculum">
      <div className="container">
        <div className="header">
          <div className="title">
            <img src={curriculum.image != null ? curriculum.image : avatar} />
            <h2>اسم المنهج: {curriculum.title}</h2>
          </div>
          <div className="info">
            <p>
              <Link to={`/profile/${curriculum.user_id}`}>
                <span>
                  <i className="fa-solid fa-chalkboard-user"></i>
                  {`تقديم المدرب:  ${curriculum.first_name} ${curriculum.last_name}`}
                </span>
              </Link>
              <span>
                <i className="fa-solid fa-star"></i>
                {`تقيم المدرب: ${curriculum.rating} نجوم`}
              </span>
              <span>
                <i className="fa-solid fa-tally"></i>
                {`عدد المشتركين: ${curriculum.subscribers} مشترك`}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="curriculum-row">
            <h3>وصف المنهج: </h3>
            <p>{curriculum.description}</p>
            <div className="curriculum-footer">
              <p>
                <i className="fa-solid fa-folder"></i>
                <Link to={`/category/${skill.category}`}>
                  التخصص: "مهارات {skill.category}"
                </Link>
              </p>
              <p>
                <i className="fa-solid fa-calendar-days"></i>
                <span>
                  <Date date={curriculum.created_date} />
                </span>
              </p>
            </div>
          </div>
          <Link to={`${curriculum.test}`} target="_blank">
            ابدأ الاختبار
          </Link>
        </div>
      </div>
    </div>
  );
}
