import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyUser } from "../../REDUX/Models/users.Model";
import { userVerify } from "../../REDUX/Slices/users.Slice";
import { setError } from "../../REDUX/Slices/users.Slice";

export default function UserVerification() {
  const user_id = useParams().id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = useSelector((state) => state.userData.id);
  const err = useSelector((state) => state.error);

  useEffect(() => {
    if (id == user_id) {
      verifyUser(user_id)
        .then(
          dispatch(userVerify()),
          setTimeout(() => {
            navigate("/loading");
          }, 2000),
        )
        .catch(() => {
          dispatch(setError("حدث خطأ اثناء تفعيل الحساب."));
        });
    } else {
      navigate("/");
    }
  });

  document.title = `طورنى - تفعيل الحساب`;
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        margin: "20px auto",
      }}
    >
      {err ? (
        <p style={{ color: "#f04141", fontSize: "28px" }}>{err}</p>
      ) : (
        <p style={{ color: "#2C744B", fontSize: "28px" }}>
          تم تفعيل الحساب الخاص بك
        </p>
      )}
    </div>
  );
}
