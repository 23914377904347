import axios from "axios";
import { token } from "./../Storage/getItems";

export const createUser = async (values) => {
  try {
    const response = await axios.post(`/api/users/`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const authenticateUser = async (values) => {
  try {
    const response = await axios.post(`/api/users/authenticate`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(`/api/users/`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUser = async (id) => {
  try {
    const response = await axios.get(`/api/users/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const searchUser = async (values) => {
  try {
    const response = await axios.post(`/api/users/search`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUser = async (id, values) => {
  try {
    const response = await axios.patch(`/api/users/update/${id}`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateRating = async (values) => {
  try {
    const response = await axios.patch(`/api/users/rating`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const verifyUser = async (id) => {
  try {
    const response = await axios.put(`/api/users/verify/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`/api/users/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const contact = async (values) => {
  try {
    const response = await axios.post(`/api/users/contact-us`, values);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
