import { useState, useEffect } from "react";
import {
  getNotifications,
  deleteNotification,
} from "../../../REDUX/Models/notifi.Model";
import { useDispatch, useSelector } from "react-redux";
import { signOut, setError } from "../../../REDUX/Slices/users.Slice";
import Date from "../../../Components/Date";
import "./index.scss";

export default function Notifications(props) {
  const id = useSelector((state) => state.userData.id);

  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    deleteNotification(id).then();
  };

  useEffect(() => {
    getNotifications(id)
      .then((res) => {
        setNotifications(res.data.notifications);
      })
      .catch(() => {
        dispatch(signOut());
      });
    dispatch(setError(null));
  }, [notifications]);

  return (
    <div
      className="dashboard-notifications"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <h2>الاشعارات</h2>
        <div className="row">
          <div className="notifi">
            {notifications.map((notification, index) => {
              return (
                <div className="notifi-row" key={index}>
                  <p>
                    {notification.text}{" "}
                    <span>
                      <Date date={notification.created_date} />
                    </span>
                  </p>
                  <button onClick={() => handleDelete(notification.id)}>
                    <i className="fa-light fa-trash"></i>حذف
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <span
          className="err"
          style={{ display: notifications.length > 0 ? "none" : "block" }}
        >
          لا يوجد اشعارات ..
        </span>
      </div>
    </div>
  );
}
