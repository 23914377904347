import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories, getSearch } from "../../REDUX/Models/skills.Model";
import { setError } from "../../REDUX/Slices/users.Slice";
import { useSelector, useDispatch } from "react-redux";
import mainImg from "./../../assets/icons/categories.svg";
import "./index.scss";

export default function Categories() {
  document.title = `طورنى - التخصصات`;

  const [categories, setCategories] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState({ search: "" });
  const [hidden, setHidden] = useState("none");

  const err = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearch({ search: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getSearch(search).then((res) => {
      setSearchResult(res.data.categories);
    });
    setHidden("flex");
  };

  const handleClick = () => {
    setSearchResult([]);
    setHidden("none");
    setSearch({ search: "" });
  };

  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch(() => {
        dispatch(setError(" خطأ عرض التخصصات"));
      });
    dispatch(setError(null));
  }, [categories]);

  return (
    <div className="categories">
      <div className="container">
        <div className="cat-header">
          <div className="text">
            <h2>
              <span>تخصصات</span> طورنى
            </h2>
            <p>تصفح تخصصات المنصة الان كما يمكنك البحث عن تخصص محدد.</p>
            <div className="search-box">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="search"
                  placeholder={
                    "ابحث بين اكثر من +" + categories.length + " تخصص"
                  }
                  value={search.search}
                  required
                  onChange={handleChange}
                />
                <button type="submit">
                  <i className="fa-light fa-magnifying-glass"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="img">
            <img src={mainImg} alt="Twrny Categories" />
          </div>
        </div>
        <div className="row">
          <h2>التخصصات المتاحة</h2>
          <div
            className="cats-row"
            style={{ display: hidden === "none" ? "flex" : "none" }}
          >
            {categories.length > 0
              ? categories.map((category, index) => (
                  <Link to={`/category/${category.category}`} key={index}>
                    <div className="cat-box">
                      <i className="fa-solid fa-compass-drafting"></i>
                      <p>تخصص</p>
                      <p>{category.category}</p>
                    </div>
                  </Link>
                ))
              : "لا يوجد تخصصات "}
          </div>
          <div className="cats-row" style={{ display: hidden }}>
            {searchResult.length > 0
              ? searchResult.map((search, index) => (
                  <Link to={`/category/${search.category}`} key={index}>
                    <div className="cat-box">
                      <i className="fa-solid fa-compass-drafting"></i>
                      <p>تخصص</p>
                      <p>{search.category}</p>
                    </div>
                  </Link>
                ))
              : `لا يوجد نتائج عن ${search.search}`}
            <div className="btn">
              <button onClick={handleClick}>جميع التخصصات</button>
            </div>
          </div>
          {err && err}
        </div>
      </div>
    </div>
  );
}
