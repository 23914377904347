export default function DateInArabic(props) {
  const date = new Date(props.date);
  const months = [
    "يناير",
    "فبراير",
    "مارس",
    "إبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  const days = [
    "اﻷحد",
    "اﻷثنين",
    "الثلاثاء",
    "اﻷربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  return (
    days[date.getDay()] +
    " " +
    date.getDate() +
    ", " +
    months[date.getMonth()] +
    ", " +
    date.getFullYear()
  );
}
