import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser, deleteUser } from "./../../../REDUX/Models/users.Model";
import {
  editUser,
  signOut,
  setError,
} from "./../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Settings(props) {
  const {
    id,
    first_name,
    last_name,
    location,
    bio,
    facebook,
    twitter,
    whatsapp,
  } = useSelector((state) => state.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    first_name: first_name,
    last_name: last_name,
    password: "",
    location: location,
    bio: bio,
    facebook: facebook,
    twitter: twitter,
    whatsapp: whatsapp,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(id, user)
      .then((res) => {
        dispatch(
          editUser({
            response: res.data.user.response,
            token: res.data.user.token,
          }),
        );
        navigate("/loading");
      })
      .catch(() => {
        dispatch(signOut());
      });
  };

  const handleDelete = () => {
    alert("هل انت متأكد من حذف حسابك");
    deleteUser(id).then(() => dispatch(signOut()));
  };

  useEffect(() => {
    dispatch(setError(null));
  }, []);

  return (
    <div
      className="dashboard-settings"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2> الاعدادات</h2>
        </div>
        <div className="row">
          <div className="info-form">
            <h3>تعديل بياناتك الشخصية</h3>
            <form className="first-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="first_name"
                placeholder="الاسم الاول"
                value={user.first_name}
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="last_name"
                placeholder="الاسم الاخير"
                value={user.last_name}
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="bio"
                placeholder="نبذة تعريفية"
                value={user.bio}
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="location"
                placeholder="ادخل مدينتك"
                value={user.location}
                required
                onChange={handleChange}
              />
              <input
                type="password"
                name="password"
                placeholder="كلمة السر"
                value={user.password}
                required
                onChange={handleChange}
              />
              <button type="submit">تعديل</button>
            </form>
          </div>
          <div className="social-form">
            <h3>اضافة روابط التواصل</h3>
            <form className="second-form" onSubmit={handleSubmit}>
              <label>
                <i className="fa-brands fa-facebook-f"></i>
              </label>
              <input
                type="text"
                name="facebook"
                placeholder="فيس بوك"
                value={user.facebook}
                onChange={handleChange}
              />
              <label>
                <i className="fa-brands fa-whatsapp"></i>
              </label>
              <input
                type="number"
                name="whatsapp"
                placeholder="واتساب"
                value={user.whatsapp}
                onChange={handleChange}
              />
              <label>
                <i className="fa-brands fa-twitter"></i>
              </label>
              <input
                type="text"
                name="twitter"
                placeholder="تويتر"
                value={user.twitter}
                onChange={handleChange}
              />
            </form>
            <button className="delete" onClick={handleDelete}>
              حذف الحساب
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
