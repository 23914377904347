import { useState, useEffect } from "react";
import {
  createSkill,
  getCategories,
  deleteSkill,
} from "./../../../../REDUX/Models/skills.Model";
import { useSelector, useDispatch } from "react-redux";
import { signOut, setError } from "./../../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Skills() {
  const [createdSkill, setCreatedSkill] = useState({
    name: "",
    category: "",
    cat_en: "",
  });
  const [categories, setCategories] = useState([]);
  const [hidden, setHidden] = useState(false);

  const dispatch = useDispatch();
  const id = useSelector((state) => state.userData.id);
  const error = useSelector((state) => state.error);

  const handleChange = (e) => {
    setCreatedSkill({
      ...createdSkill,
      [e.target.name]: e.target.value,
      user_id: id,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSkill(createdSkill)
      .then(setCreatedSkill({ name: "", category: "", cat_en: "" }))
      .catch(() => {
        dispatch(signOut());
      });
  };

  const handleDelete = (id) => {
    deleteSkill(id).then(
      setTimeout(() => {
        dispatch(setError(null));
      }, 1000),
    );
    dispatch(setError("تم حذف التخصص"));
  };

  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch(() => {
        dispatch(setError("خطأ عرض التخصصات"));
      });
  }, [categories]);

  return (
    <div
      className="admin-categories"
      style={{ display: hidden ? "none" : "block" }}
    >
      <div className="container">
        <header>
          <h2>التخصصات</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <div className="row">
          <div className="add-cat">
            <h3>اضف تخصص جديد</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="افتتح التخصص بمهارة جديدة"
                value={createdSkill.name}
                required
                onChange={handleChange}
              />
              <label>التخصص</label>
              <input
                type="text"
                name="category"
                placeholder={`عدد التخصصات: ${categories.length}`}
                value={createdSkill.category}
                required
                onChange={handleChange}
              />
              <input
                type="text"
                name="cat_en"
                placeholder="التخصص بـالانجليزية"
                value={createdSkill.cat_en}
                required
                onChange={handleChange}
              />
              <button type="submit">اضافة</button>
            </form>
          </div>
          <div className="cats">
            {categories.length > 0 ? (
              categories.map((category, index) => {
                return (
                  <div className="cat-box" key={index}>
                    <i className="fa-solid fa-compass-drafting"></i>
                    <p>{category.category}</p>
                    <span onClick={() => handleDelete(category.id)}>
                      <i className="fa-light fa-trash"></i>
                      <p>حذف</p>
                    </span>
                  </div>
                );
              })
            ) : (
              <span>لا يوجد تخصصات للعرض ..</span>
            )}
          </div>
          {error && <span>{error}</span>}
        </div>
      </div>
    </div>
  );
}
