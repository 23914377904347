import axios from "axios";
import { token } from "./../Storage/getItems";

export const createTest = async (values) => {
  try {
    const response = await axios.post(`/api/tests/`, values, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTest = async (id) => {
  try {
    const response = await axios.get(`/api/tests/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTests = async () => {
  try {
    const response = await axios.get(`/api/tests`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUsersTests = async () => {
  try {
    const response = await axios.get(`/api/tests/users/tests`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTest = async (id) => {
  try {
    const response = await axios.delete(`/api/tests/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
