import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./../../REDUX/Models/users.Model";
import { getSkills } from "./../../REDUX/Models/skills.Model";
import { getUserCurriculums } from "../../REDUX/Models/curriculums.Model";
import {
  addFriend,
  getFriends,
  checkFriends,
} from "./../../REDUX/Models/friends.Model";
import { getUserCertificates } from "../../REDUX/Models/certificates.Model";
import { createNotifi } from "../../REDUX/Models/notifi.Model";
import { signOut } from "../../REDUX/Slices/users.Slice";
import avatar from "././../../assets/icons/avatar.svg";
import Curriculums from "./Curriculums";
import Friends from "./Friends";
import Skills from "./Skills";
import Certificate from "../../Components/Certificate";
import "./index.scss";

export default function Profile() {
  const user_id = useParams().id;

  const [text, setText] = useState();
  const [user, setUser] = useState({});
  const [friend, setFriend] = useState({ user_id_from: "", user_id_to: "" });
  const [friends, setFriends] = useState([]);
  const [skills, setSkills] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [certificates, setCertificate] = useState([]);
  const [disable, setDisable] = useState(false);

  const [skillsHidden, setSkillsHidden] = useState(true);
  const [friendsHidden, setFriendsHidden] = useState(true);
  const [curriculumsHidden, setCurriculumsHidden] = useState(false);
  const [certificatesHidden, setCertificatesHidden] = useState(true);

  const { userData } = useSelector((state) => state);
  const user_id_from = useSelector((state) => state.userData.id);
  const isSigned = useSelector((state) => state.isSigned);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (id) => {
    addFriend(id, { user_id: user_id_from })
      .then(() => {
        createNotifi({
          text: `ارسل ${userData.first_name}${" "}${
            userData.last_name
          } طلب صداقةاليك .. انظر الاصدقاء`,
          user_id: id,
        });
        createNotifi({
          text: `لقد اصبحت صديقا ً مع  ${user.first_name}${" "}${
            user.last_name
          }`,
          user_id: user_id_from,
        });
        window.location.reload();
      })
      .catch(() => {
        dispatch(signOut());
      });
  };

  useEffect(() => {
    if (isSigned === true) {
      getUser(user_id)
        .then((res) => {
          if (res.data.user) {
            setUser(res.data.user);
          } else {
            navigate("/");
          }
          getSkills(user_id).then((res) => {
            setSkills(res.data.skills);
          });
          getUserCurriculums(user_id).then((res) => {
            setCurriculums(res.data.curriculums);
          });
          getUserCertificates(user_id).then((res) => {
            setCertificate(res.data.certificates);
          });
          getFriends(user_id).then((res) => {
            setFriends(res.data.friends);
          });
        })
        .catch(() => {
          dispatch(signOut());
        });
      checkFriends(user_id, { user_id: user_id_from })
        .then((res) => {
          if (res.data.friend) {
            setFriend(res.data.friend);
          } else {
            setFriend({ user_id_from: "", user_id_to: "" });
          }
        })
        .catch(() => {
          dispatch(signOut());
        });
    } else {
      navigate(`/profile/view/${user_id}`);
    }
  }, [user_id, isSigned]);

  useEffect(() => {
    if (user_id_from == user.id) {
      setText("صفحتى");
      setDisable(true);
    } else if (user_id == friend.user_id_to) {
      setText("اصدقاء");
      setDisable(true);
    } else {
      setText("ارسال طلب صداقة");
    }
  });

  document.title = `طورنى - ${user.first_name}${" "}${user.last_name}`;
  return (
    <div className="profile">
      <div className="container">
        <h2 className="header">الصفحة الشخصية</h2>
        <div className="row">
          <div className="profile-img">
            <img src={avatar} alt="Profile Image" />
          </div>
          <div className="profile-info">
            <div className="info">
              <div className="info-header">
                <h2>
                  {user.first_name} {user.last_name}
                </h2>
                <button onClick={() => handleClick(user.id)} disabled={disable}>
                  {text}
                </button>
              </div>
              <i className="fa-light fa-at"></i>
              <p>{user.user_name}</p>
            </div>
            <div className="location">
              <i className="fa-light fa-location-dot"></i>
              <p>
                {user.location ? user.location : <span>لا يوجد مدينة.</span>}
              </p>
            </div>
            <div className="rating">
              <i className="fa-solid fa-star"></i>
              <p>
                {user.rating ? (
                  <span>{`${user.rating} نجوم`}</span>
                ) : (
                  <span>لا يوجد تقيم.</span>
                )}
              </p>
            </div>
            <div className="bio">
              <i className="fa-solid fa-hashtag"></i>
              <p>{user.bio ? user.bio : <span>لا يوجد نبذة تعريفية.</span>}</p>
            </div>
          </div>
        </div>
        <div className="profile-content">
          <div className="toggle">
            <p
              onClick={() => {
                setCurriculumsHidden(false);
                setSkillsHidden(true);
                setCertificatesHidden(true);
                setFriendsHidden(true);
              }}
            >
              المناهج <i className="fa-solid fa-graduation-cap"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(false);
                setCertificatesHidden(true);
                setFriendsHidden(true);
              }}
            >
              المهارات <i className="fa-solid fa-shapes"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(true);
                setCertificatesHidden(false);
                setFriendsHidden(true);
              }}
            >
              الشهادات <i className="fa-solid fa-file-certificate"></i>
            </p>
            <p
              onClick={() => {
                setCurriculumsHidden(true);
                setSkillsHidden(true);
                setCertificatesHidden(true);
                setFriendsHidden(false);
              }}
            >
              الاصدقاء <i className="fa-solid fa-user-group"></i>
            </p>
          </div>
          <div className="component">
            <div
              className="profile-curriculums"
              style={{ display: curriculumsHidden ? "none" : "flex" }}
            >
              {curriculums.length > 0 ? (
                curriculums.map((curriculum, index) => {
                  return <Curriculums key={index} curriculum={curriculum} />;
                })
              ) : (
                <span className="noData">لا يوجد مناهج للعرض.</span>
              )}
            </div>
            <div
              className="profile-skills"
              style={{ display: skillsHidden ? "none" : "flex" }}
            >
              {skills.length > 0 ? (
                skills.map((skill, index) => {
                  return <Skills key={index} skill={skill} />;
                })
              ) : (
                <span className="noData">لا يوجد مهارات للعرض.</span>
              )}
            </div>
            <div
              className="profile-certificates"
              style={{ display: certificatesHidden ? "none" : "flex" }}
            >
              {certificates.length > 0 ? (
                certificates.map((certificate, index) => {
                  return (
                    <Certificate key={index} certificateProp={certificate} />
                  );
                })
              ) : (
                <span className="noData">لا يوجد شهادات للعرض.</span>
              )}
            </div>
            <div
              className="profile-friends"
              style={{ display: friendsHidden ? "none" : "flex" }}
            >
              {friends.length > 0 ? (
                friends.map((friend, index) => {
                  return <Friends key={index} friend={friend} />;
                })
              ) : (
                <span className="noData">لا يوجد اصدقاء للعرض.</span>
              )}
            </div>
          </div>
        </div>
        <div className="user-contact">
          <h2>معلومات التواصل</h2>
          <div className="contact-info">
            {user.facebook ? (
              <Link to={user.facebook} target="_blank">
                <div className="social-box">
                  <p>Facebook</p>
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
              </Link>
            ) : (
              "لا يوجد معلومات للتواصل."
            )}
            {user.twitter ? (
              <Link to={user.twitter} target="_blank">
                <div className="social-box">
                  <p>Twitter</p>
                  <i className="fa-brands fa-twitter"></i>
                </div>
              </Link>
            ) : (
              ""
            )}
            {user.whatsapp ? (
              <Link to={`https://wa.me/${user.whatsapp}`} target="_blank">
                <div className="social-box">
                  <p>Whatsapp</p>
                  <i className="fa-brands fa-whatsapp"></i>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
