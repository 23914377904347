import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

export default function Ads() {
  const [adHeader, setAdHeader] = useState();
  const [hidden, setHidden] = useState(false);
  const [disable, setDisable] = useState();

  const handleChange = (e) => {
    setAdHeader(e.target.value);
  };

  const script = document.getElementById("adsense");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (adHeader.length > 0) {
      script.innerHTML = adHeader;
      setDisable(true);
    }
  };

  return (
    <div className="admin-ads" style={{ display: hidden ? "none" : "block" }}>
      <div className="container">
        <header>
          <h2>الاعلانات</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <p>*تنوية : لا يجب حذف او تعديل هذا الكود بعد ربطة بالموقع ..</p>
        <div className="row">
          <form onSubmit={handleSubmit}>
            <label>الصق كود الـ header</label>
            <input
              name="adHeader"
              value={adHeader}
              placeholder={
                script.innerHTML.length > 0
                  ? script.innerHTML
                  : "الصق كود بين <script> هنا"
              }
              required
              disabled={disable}
              onChange={handleChange}
            />
            <div className="btns">
              <button type="submit">تأكيد</button>
              <Link to="https://adsense.google.com/" target="_blank">
                الانتقال الى الارباح
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
