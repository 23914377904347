import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setError, signOut } from "./../../REDUX/Slices/users.Slice";
import { getTest } from "./../../REDUX/Models/tests.Model";
import { createAnswer } from "../../REDUX/Models/answers.Model";
import { createNotifi } from "../../REDUX/Models/notifi.Model";
import submit from "./../../assets/submit.gif";
import "./index.scss";

export default function Core(props) {
  const user_id = useSelector((state) => state.userData.id);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [q1Hidden, setQ1hidden] = useState(false);
  const [q2Hidden, setQ2hidden] = useState(true);
  const [q3Hidden, setQ3hidden] = useState(true);
  const [q4Hidden, setQ4hidden] = useState(true);
  const [q5Hidden, setQ5hidden] = useState(true);
  const [questions, setQuestions] = useState({});
  const [answers, setAnswers] = useState({
    a1: "",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
  });
  const [countdown, setCountDown] = useState(300);
  const [hidden, setHidden] = useState(true);

  const handleChange = (e) => {
    setAnswers({
      ...answers,
      [e.target.name]: e.target.value.toLowerCase(),
      test_id: questions.id,
      category_id: questions.category_id,
      user_id: user_id,
    });
  };

  const handleSubmit = () => {
    createAnswer(answers)
      .then(() => {
        createNotifi({
          user_id: user_id,
          text: "تم تسجيل اجوبتك وجارى الان مراجعتها .. النتيجة خلال 24 ساعة",
        }).then(() => {
          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
          setHidden(false);
        });
      })
      .catch(() => {
        dispatch(setError("لم يتم تسجيل اجوبتك."));
      });
  };

  const showQ1 = () => {
    setQ1hidden(false);
    setQ2hidden(true);
    setQ3hidden(true);
    setQ4hidden(true);
    setQ5hidden(true);
  };
  const showQ2 = () => {
    setQ1hidden(true);
    setQ2hidden(false);
    setQ3hidden(true);
    setQ4hidden(true);
    setQ5hidden(true);
  };

  const showQ3 = () => {
    setQ1hidden(true);
    setQ2hidden(true);
    setQ3hidden(false);
    setQ4hidden(true);
    setQ5hidden(true);
  };
  const showQ4 = () => {
    setQ1hidden(true);
    setQ2hidden(true);
    setQ3hidden(true);
    setQ4hidden(false);
    setQ5hidden(true);
  };
  const showQ5 = () => {
    setQ1hidden(true);
    setQ2hidden(true);
    setQ3hidden(true);
    setQ4hidden(true);
    setQ5hidden(false);
  };

  useEffect(() => {
    getTest(props.params)
      .then((res) => {
        if (res.data.test) {
          setQuestions(res.data.test);
          dispatch(setError(null));
        } else {
          dispatch(signOut());
          navigate("/");
        }
      })
      .catch(() => {
        navigate("/");
      });
  }, []);

  useEffect(() => {
    if (props.hidden == false) {
      let interval = setInterval(() => {
        clearInterval(interval);
        setCountDown(countdown - 1);
        if (countdown == "0") {
          setCountDown("انتهى الوقت المحدد.");
        }
        if (countdown == "انتهى الوقت المحدد.") navigate("/dashboard");
      }, 1000);
    }
  });

  return (
    <div
      className="Core-Test"
      style={{ display: props.hidden === false ? "block" : "none" }}
    >
      <div className="container">
        <div className="qs">
          <div
            className="question"
            style={{ display: q1Hidden ? "none" : "block" }}
          >
            <h2>{questions.q1}</h2>
            <label>ادخل اجابتك ..</label>
            <textarea
              name="a1"
              value={answers.a1}
              placeholder="لا يوجد اجابة صحيحة او خاطئة"
              required
              onChange={handleChange}
            />
            {answers.a1.length > 1 ? (
              <button onClick={showQ2}>التالى</button>
            ) : (
              ""
            )}
          </div>
          <div
            className="question"
            style={{ display: q2Hidden ? "none" : "block" }}
          >
            <h2>{questions.q2}</h2>
            <label>ادخل اجابتك ..</label>
            <textarea
              name="a2"
              value={answers.a2}
              placeholder="لا يوجد اجابة صحيحة او خاطئة"
              required
              onChange={handleChange}
            />
            <div className="btns">
              <button onClick={showQ1}>السابق</button>
              {answers.a2.length > 1 ? (
                <button onClick={showQ3}>التالى</button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="question"
            style={{ display: q3Hidden ? "none" : "block" }}
          >
            <h2>{questions.q3}</h2>
            <label>ادخل اجابتك ..</label>
            <textarea
              name="a3"
              value={answers.a3}
              placeholder="لا يوجد اجابة صحيحة او خاطئة"
              required
              onChange={handleChange}
            />
            <div className="btns">
              <button onClick={showQ2}>السابق</button>
              {answers.a3.length > 1 ? (
                <button onClick={showQ4}>التالى</button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="question"
            style={{ display: q4Hidden ? "none" : "block" }}
          >
            <h2>{questions.q4}</h2>
            <label>ادخل اجابتك ..</label>
            <textarea
              name="a4"
              value={answers.a4}
              placeholder="لا يوجد اجابة صحيحة او خاطئة"
              required
              onChange={handleChange}
            />
            <div className="btns">
              <button onClick={showQ3}>السابق</button>
              {answers.a4.length > 1 ? (
                <button onClick={showQ5}>التالى</button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="question"
            style={{ display: q5Hidden ? "none" : "block" }}
          >
            <h2>{questions.q5}</h2>
            <label>ادخل اجابتك ..</label>
            <textarea
              name="a5"
              value={answers.a5}
              placeholder="لا يوجد اجابة صحيحة او خاطئة"
              required
              onChange={handleChange}
            />
            <div className="btns">
              <button onClick={showQ4}>السابق</button>
              {answers.a5.length > 1 ? (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  style={{ display: q5Hidden ? "none" : "block" }}
                >
                  ارسال
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="count-down">
          <p style={{ display: hidden === true ? "block" : "none" }}>
            {countdown}
            {error && <span>{error}</span>}
          </p>
          <img
            style={{ display: hidden === true ? "none" : "block" }}
            src={submit}
            alt="Test Submitted"
          />
        </div>
      </div>
    </div>
  );
}
