import { useNavigate } from "react-router-dom";
import form from "./../../assets/icons/form.svg";
import teach from "./../../assets/icons/teach.svg";
import coin from "./../../assets/icons/coin.svg";
import arrow1 from "./../../assets/imgs/arrow1.png";
import arrow2 from "./../../assets/imgs/arrow2.png";
import arrow3 from "./../../assets/imgs/arrow3.png";
import "./index.scss";

export default function Terms() {
  document.title = `طورنى -  طريقة الاستخدام`;

  const navigate = useNavigate();

  return (
    <div className="terms">
      <div className="container">
        <h1>
          كيف تستخدم<span>طورنى</span>
        </h1>
        <section className="first-sec">
          <div className="row">
            <div className="text">
              <h4>
                <span>1</span> الخطوة الاولى
              </h4>
              <h2>سجل في طورنى</h2>
              <p>
                سجل الأن في موقع طورنى وقم بنشر مهاراتك مع العديد من المستخدمين
                حول الوطن العربي.
              </p>
              <button onClick={() => navigate("/login")}>ابدأ معنا</button>
            </div>
            <div className="img">
              <img src={form} alt="how to use twrny" />
            </div>
          </div>
        </section>
        <div className="first-arr">
          <img src={arrow1} alt="arrow" />
        </div>
        <section className="second-sec">
          <div className="row">
            <div className="img">
              <img src={teach} alt="how to use twrny" />
            </div>
            <div className="text">
              <h4>
                <span>2</span> الخطوة الثانية
              </h4>
              <h2>انشر مهاراتك</h2>
              <p>توفر منصة طورنى لوحة تحكم لكل مستخدم لتسهيل نشر مهاراتة.</p>
              <button onClick={() => navigate("/about")}>اعرف اكثر</button>
            </div>
          </div>
        </section>
        <div className="second-arr">
          <img src={arrow2} alt="arrow" />
        </div>
        <section className="third-sec">
          <div className="row">
            <div className="text">
              <h4>
                <span>3</span> الخطوة الثالثة
              </h4>
              <h2>اربح جوائز قيمة</h2>
              <p>تعلم وساعد في نشر المهارات واحصل على مكافأت قيمة.</p>
              <button onClick={() => navigate("/register")}>سجل الأن</button>
            </div>
            <div className="img">
              <img src={coin} alt="how to use twrny" />
            </div>
          </div>
        </section>
        <div className="third-arr">
          <img src={arrow3} alt="arrow" />
        </div>
      </div>
    </div>
  );
}
