import { useState, useEffect } from "react";
import { getUserCertificates } from "./../../../REDUX/Models/certificates.Model";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../REDUX/Slices/users.Slice";
import Certificate from "../../../Components/Certificate";
import "./index.scss";

export default function Certificates(props) {
  const [certificates, setCertificates] = useState([{}]);

  const user_id = useSelector((state) => state.userData.id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getUserCertificates(user_id).then((res) => {
      setCertificates(res.data.certificates);
    });
    dispatch(setError(null));
  }, [certificates]);

  return (
    <div
      className="dashboard-certificates"
      style={{ display: props.hidden === true ? "block" : "none" }}
    >
      <div className="container">
        <div className="header">
          <h2> الشهادات</h2>
        </div>
        <div className="row">
          <div className="user-certificates">
            {certificates.length > 0 ? (
              certificates.map((certificate, index) => {
                return (
                  <div key={index}>
                    <Certificate certificateProp={certificate} />
                    <button
                      onClick={() => navigate(`/verify/${certificate.cer_id}`)}
                    >
                      عرض
                    </button>
                  </div>
                );
              })
            ) : (
              <span>لم يتم تحقيق شهادات حتى الان. </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
