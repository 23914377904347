import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "./../../REDUX/Slices/users.Slice";
import { getNotifications } from "../../REDUX/Models/notifi.Model";
import Logo from "./../../assets/Logo.svg";
import Tests from "./Tests";
import Skills from "./Skills";
import Profile from "./Profile";
import Friends from "./Friends";
import Settings from "./Settings";
import Curriculums from "./Curriculums";
import Certificates from "./Certificates";
import Notifications from "./Notifications";
import "./index.scss";

export default function Dashboard() {
  document.title = `طورنى - لوحة التحكم`;

  const [length, setLength] = useState();
  const [hidden, setHidden] = useState(false);

  const [testsHidden, setTestsHidden] = useState(false);
  const [skillsHidden, setSkillsHidden] = useState(false);
  const [notifiHidden, setNotifiHidden] = useState(false);
  const [profileHidden, setProfileHidden] = useState(true);
  const [friendsHidden, setFriendsHidden] = useState(false);
  const [settingsHidden, setSettingsHidden] = useState(false);
  const [curriculumsHidden, setCurriculumsHidden] = useState(false);
  const [certificatesHidden, setCertificatesHidden] = useState(false);

  const showProfile = () => {
    setProfileHidden(true);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };
  const showSkills = () => {
    setProfileHidden(false);
    setSkillsHidden(true);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };
  const showTests = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(true);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };
  const showFriends = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(true);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };
  const showCertificates = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(true);
  };
  const showCurriculum = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(true);
    setCertificatesHidden(false);
  };
  const showNotifi = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(true);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };
  const showSettings = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(true);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
  };

  const showMobileProfile = () => {
    setProfileHidden(true);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileSkills = () => {
    setProfileHidden(false);
    setSkillsHidden(true);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileTests = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(true);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileFriends = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(true);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileCertificates = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(true);
    setHidden(true);
  };
  const showMobileCurriculum = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(true);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileNotifi = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(false);
    setTestsHidden(false);
    setNotifiHidden(true);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };
  const showMobileSettings = () => {
    setProfileHidden(false);
    setSkillsHidden(false);
    setFriendsHidden(false);
    setSettingsHidden(true);
    setTestsHidden(false);
    setNotifiHidden(false);
    setCurriculumsHidden(false);
    setCertificatesHidden(false);
    setHidden(true);
  };

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.userData.id);

  useEffect(() => {
    getNotifications(user_id).then((res) => {
      setLength(res.data.notifications.length);
    });
  }, []);

  return (
    <div className="dashboard">
      <div className="container">
        <div className="row">
          <span
            className="m-icon"
            onClick={() => setHidden(!hidden)}
            style={{ display: hidden === false ? "none" : "block" }}
          >
            <i className="fa-light fa-compass"></i>
          </span>
          <div className="sidebar">
            <aside>
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Twrny-Logo" />
                </Link>
              </div>
              <div className="navigation">
                <span
                  className={profileHidden === true ? "active" : ""}
                  onClick={() => showProfile()}
                >
                  <i className="fa-light fa-user"></i>
                  <p>المعلومات</p>
                </span>
                <span
                  className={skillsHidden === true ? "active" : ""}
                  onClick={() => showSkills()}
                >
                  <i className="fa-light fa-folder"></i>
                  <p>المهارات</p>
                </span>
                <span
                  className={curriculumsHidden === true ? "active" : ""}
                  onClick={() => showCurriculum()}
                >
                  <i className="fa-light fa-graduation-cap"></i>
                  <p>المناهج</p>
                </span>
                <span
                  className={certificatesHidden === true ? "active" : ""}
                  onClick={() => showCertificates()}
                >
                  <i className="fa-light fa-file-certificate"></i>
                  <p>الشهادات</p>
                </span>
                <span
                  className={friendsHidden === true ? "active" : ""}
                  onClick={() => showFriends()}
                >
                  <i className="fa-light fa-user-group"></i>
                  <p>الاصدقاء</p>
                </span>
                <span
                  className={testsHidden === true ? "active" : ""}
                  onClick={() => showTests()}
                >
                  <i className="fa-light fa-pen-field"></i>
                  <p>الاختبارات</p>
                </span>
                <span
                  className={notifiHidden === true ? "active" : ""}
                  onClick={() => showNotifi()}
                >
                  <i className="fa-light fa-bell">
                    {length > 0 ? <span>{length}</span> : ""}
                  </i>
                  <p>الاشعارات</p>
                </span>
                <span
                  className={settingsHidden === true ? "active" : ""}
                  onClick={() => showSettings()}
                >
                  <i className="fa-light fa-gear"></i>
                  <p>الاعدادات</p>
                </span>
              </div>
              <button onClick={() => dispatch(signOut())}>تسجيل خروج</button>
            </aside>
          </div>
          <div
            className="mobile-sidebar"
            style={{ display: hidden === true ? "none" : "flex" }}
          >
            <aside>
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Twrny-Logo" />
                </Link>
              </div>
              <div className="navigation">
                <span
                  className={profileHidden === true ? "active" : ""}
                  onClick={() => showMobileProfile()}
                >
                  <i className="fa-light fa-user"></i>
                  <p>المعلومات</p>
                </span>
                <span
                  className={skillsHidden === true ? "active" : ""}
                  onClick={() => showMobileSkills()}
                >
                  <i className="fa-light fa-folder"></i>
                  <p>المهارات</p>
                </span>
                <span
                  className={curriculumsHidden === true ? "active" : ""}
                  onClick={() => showMobileCurriculum()}
                >
                  <i className="fa-light fa-graduation-cap"></i>
                  <p>المناهج</p>
                </span>
                <span
                  className={certificatesHidden === true ? "active" : ""}
                  onClick={() => showMobileCertificates()}
                >
                  <i className="fa-light fa-file-certificate"></i>
                  <p>الشهادات</p>
                </span>
                <span
                  className={friendsHidden === true ? "active" : ""}
                  onClick={() => showMobileFriends()}
                >
                  <i className="fa-light fa-user-group"></i>
                  <p>الاصدقاء</p>
                </span>
                <span
                  className={testsHidden === true ? "active" : ""}
                  onClick={() => showMobileTests()}
                >
                  <i className="fa-light fa-pen-field"></i>
                  <p>الاختبارات</p>
                </span>
                <span
                  className={notifiHidden === true ? "active" : ""}
                  onClick={() => showMobileNotifi()}
                >
                  <i className="fa-light fa-bell">
                    {length > 0 ? <span>{length}</span> : ""}
                  </i>
                  <p>الاشعارات</p>
                </span>
                <span
                  className={settingsHidden === true ? "active" : ""}
                  onClick={() => showMobileSettings()}
                >
                  <i className="fa-light fa-gear"></i>
                  <p>الاعدادات</p>
                </span>
              </div>
              <button onClick={() => dispatch(signOut())}>تسجيل خروج</button>
            </aside>
          </div>
          <div className="content">
            <Tests hidden={testsHidden} />
            <Skills hidden={skillsHidden} />
            <Friends hidden={friendsHidden} />
            <Settings hidden={settingsHidden} />
            <Notifications hidden={notifiHidden} />
            <Curriculums hidden={curriculumsHidden} />
            <Certificates hidden={certificatesHidden} />
            <Profile hidden={profileHidden} function={() => showSettings()} />
          </div>
        </div>
      </div>
    </div>
  );
}
