import { useState, useEffect } from "react";
import {
  getPublicCurriculums,
  getPrivateCurriculums,
  publishCurriculum,
  updateCurriculum,
  deleteCurriculum,
} from "./../../../../REDUX/Models/curriculums.Model";
import { createNotifi } from "./../../../../REDUX/Models/notifi.Model";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../REDUX/Slices/users.Slice";
import "./index.scss";

export default function Curriculums() {
  const [privateCurriculums, setPrivateCurriculums] = useState([]);
  const [publicCurriculums, setPublicCurriculums] = useState([]);
  const [subscribers, setSubscribers] = useState();
  const [notifi, setNotifi] = useState({ text: "", user_id: "" });
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (id, user_id) => {
    publishCurriculum(id)
      .then(
        createNotifi({
          text: `تم نشر المنهج الخاص بك .. انظر المناهج`,
          user_id: user_id,
        }),
      )
      .catch(() => dispatch(signOut()));
  };
  const handleUpdate = (el) => {
    updateCurriculum(el.id, {
      title: el.title,
      description: el.description,
      test: el.test,
      public: true,
      subscribers: subscribers,
      category_id: el.category_id,
      user_id: el.user_id,
    }).catch(() => {
      alert("خطأ تحديث المنهج.");
      window.location.reload();
    });
    setSubscribers();
    setNotifi({ text: "", user_id: "" });
  };
  const handleDelete = (id) => {
    deleteCurriculum(id)
      .then()
      .catch(() => dispatch(signOut()));
  };

  useEffect(() => {
    getPrivateCurriculums()
      .then((res) => {
        setPrivateCurriculums(res.data.curriculums);
      })
      .catch(() => {
        dispatch(signOut());
      });
  }, [privateCurriculums]);

  useEffect(() => {
    getPublicCurriculums()
      .then((res) => {
        setPublicCurriculums(res.data.curriculums);
      })
      .catch(() => {
        dispatch(signOut());
      });
  }, [publicCurriculums]);

  return (
    <div
      className="admin-curriculums"
      style={{ display: hidden ? "none" : "block" }}
    >
      <div className="container">
        <header>
          <h2>المناهج</h2>
          <p onClick={() => setHidden(!hidden)}>
            اغلاق<i className="fa-light fa-x"></i>
          </p>
        </header>
        <p>* اضغط على اسم المنهج للعرض ..</p>
        <div className="row">
          <div className="in-review">
            <h2>قيد المراجعة {`(${privateCurriculums.length})`}</h2>
            {privateCurriculums.length > 0
              ? privateCurriculums.map((el, index) => {
                  return (
                    <div className="curr" key={index}>
                      <div className="curr-box">
                        <Link to={`/curriculum/${el.id}`} target="_blank">
                          <p>
                            <span>اسم المنهج: </span>
                            {el.title}
                          </p>
                        </Link>
                        <p>
                          <span>وصف المنهج: </span>
                          {el.description}
                        </p>
                        <p>
                          <span>رابط الاختبار: </span>
                          <Link to={`${el.test}`} target="_blank">
                            الاختبار
                          </Link>
                        </p>
                        <button onClick={() => handleClick(el.id, el.user_id)}>
                          قبول
                        </button>
                        <button onClick={() => handleDelete(el.id)}>حذف</button>
                      </div>
                    </div>
                  );
                })
              : "لا يوجد مناهج قيد المراجعة .."}
          </div>
          <div className="public">
            <h2>مناهج عامة {`(${publicCurriculums.length})`}</h2>
            {publicCurriculums.length > 0
              ? publicCurriculums.map((el, index) => {
                  return (
                    <div className="curr" key={index}>
                      <div className="curr-box">
                        <Link to={`/curriculum/${el.id}`} target="_blank">
                          <p>
                            <span>اسم المنهج: </span>
                            {el.title}
                          </p>
                        </Link>
                        <p>
                          <span>وصف المنهج: </span>
                          {el.description}
                        </p>
                        <p>
                          <span>رابط الاختبار: </span>
                          <Link to={`${el.test}`} target="_blank">
                            الاختبار
                          </Link>
                        </p>
                        <label>المشتركين{`(${el.subscribers})`}</label>
                        <input
                          type="number"
                          placeholder="اضافة مشتركين"
                          value={subscribers}
                          onChange={(e) => setSubscribers(e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="ارسال اشعار"
                          value={notifi.text}
                          onChange={(e) =>
                            setNotifi({
                              text: e.target.value,
                              user_id: el.user_id,
                            })
                          }
                        />
                        <label
                          onClick={() => {
                            createNotifi(notifi);
                            setNotifi({ text: "", user_id: "" });
                          }}
                        >
                          <i className="fa-light fa-paper-plane"></i>
                        </label>
                        <div className="btns">
                          <button onClick={() => handleUpdate(el)}>
                            تحديث
                          </button>
                          <button onClick={() => handleDelete(el.id)}>
                            حذف
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "لا يوجد مناهج عامة .."}
          </div>
        </div>
      </div>
    </div>
  );
}
